import React from 'react'
import "./ppcmanagement.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import PpcInclude from "../../Component/Assest/pages/ppc_img/PPC_Management.svg"
import CircleArrow from "../../Component/Assest/pages/ppc_img/circle_arrow.svg"
import Essential from "../../Component/Assest/pages/ppc_img/essential.svg"
import { PiArrowCircleRightFill } from "react-icons/pi";
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const PpcManagement = () => {

    const PPCMedia = [
        {
            delay: "400",
            title: "PPC Strategy & Consulting",
            desc: "Maximize the return on investment (ROI) of your paid digital advertising spend with a holistic strategy that applies cross-platform, data-driven intelligence to continuously improve the performance of your ads.",
        },
        {
            delay: "500",
            title: "Paid Search",
            desc: "Drive qualified leads with paid search ad campaigns. We manage and optimize paid search ads on Google and other search engines such as Bing.",
        },
        {
            delay: "600",
            title: "Paid Social",
            desc: "Reach your audience where they spend time online with targeted paid social ads on all major social platforms, including YouTube, Facebook, Instagram, LinkedIn, Twitter, TikTok, and others.",
        },
        {
            delay: "500",
            title: "Remarketing/retargeting",
            desc: "Capitalize on audiences that have already engaged with your ads and/or website through remarketing and retargeting campaigns across search engines and social platforms.",
        },
        {
            delay: "600",
            title: "Landing Page Creation",
            desc: "Convert more paid traffic into leads and revenue through conversion optimized landing pages that will drive results.",
        },
        {
            delay: "700",
            title: "Display network",
            desc: "Reach more people in more places and target new affinity audiences that your paid search ads might miss with ads placed on Google’s display network.",
        },
        {
            delay: "800",
            title: "Ad Creative",
            desc: "From ad copy, images, and video to landing page copy and design, we create all the digital assets you need to make your paid ads eye-catching and compelling.",
        },
        {
            delay: "900",
            title: "Conversion Rate Optimization",
            desc: "Maximize conversions and minimize costs with paid digital advertising conversion rate optimization (CRO).",
        },
    ]

    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section className='ppc_management_section'>
                <div className='ppc_management_bg'>
                    <p>SEM, PPC & Paid Social</p>
                    <h1>Pay-Per-Click (PPC) <br /> Management</h1>
                </div>

                <div className='ppc_paid_management'>
                    <h2>Paid Media Management Services</h2>
                    <div className='ppc_paid_management_flex'>
                        {
                            PPCMedia.map((paidppc, index) => (
                                <div className='paid_cards' key={index} data-aos="fade-up"
                                    data-aos-duration="4000"
                                    data-aos-delay={paidppc.delay}>
                                    <h3>{paidppc.title}</h3>
                                    <p>{paidppc.desc}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='ppc_math_content'>
                    <div className='text' data-aos="fade-up"
                        data-aos-duration="4000">
                        <h1>–28%</h1>
                        <p>+30%</p>
                    </div>
                    <div className='text' data-aos="fade-up"
                        data-aos-duration="5000">
                        <h1>+30%</h1>
                        <p>Increase in eCommerce Sales</p>
                    </div>
                    <div className='text' data-aos="fade-up"
                        data-aos-duration="6000">
                        <h1>+40%</h1>
                        <p>Increase in Click Through Rate
                            (CTR)</p>
                    </div>
                    <div className='text' data-aos="fade-up"
                        data-aos-duration="7000">
                        <h1>–35%</h1>
                        <p>Reduction in Cost Per Click
                            (CPC)</p>
                    </div>
                </div>

                <div className='ppc_include'>
                    <div className='ppc_include_flex'>
                        <div className='image' data-aos="fade-right" data-aos-duration="4000">
                            <img src={PpcInclude} alt='PpcInclude' />
                        </div>
                        <div className='content' data-aos="fade-left" data-aos-duration="4000">
                            <h1>What’s Included in Monthly PPC Management?</h1>
                            <p>Our agency’s PPC management services include the following tasks as part of monthly management:</p>
                            <div className='arrow_flex'>
                                <img src={CircleArrow} />
                                <p>Bid Management</p>
                            </div>
                            <div className='arrow_flex'>
                                <img src={CircleArrow} />
                                <p>Landing Page Performance Review</p>
                            </div>
                            <div className='arrow_flex'>
                                <img src={CircleArrow} />
                                <p>Ad Placement and Targeting Adjustments</p>
                            </div>
                            <div className='arrow_flex'>
                                <img src={CircleArrow} />
                                <p>Cost and Performance Analysis</p>
                            </div>
                            <div className='arrow_flex'>
                                <img src={CircleArrow} />
                                <p>Geo-targeting Improvements</p>
                            </div>
                            <div className='arrow_flex'>
                                <img src={CircleArrow} />
                                <p>Check In Calls/Meetings</p>
                            </div>
                            <div className='arrow_flex'>
                                <img src={CircleArrow} />
                                <p>Keyword Management</p>
                            </div>
                            <div className='arrow_flex'>
                                <img src={CircleArrow} />
                                <p>Monthly Campaign Performance Report</p>
                            </div>
                            <div className='arrow_flex'>
                                <img src={CircleArrow} />
                                <p>Ad Copy Optimization and Testing</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='ppc_essential'>
                    <h2>Essential Reading – Paid Media</h2>
                    <div className='ppc_essential_flex' data-aos="fade-up"
                        data-aos-duration="4000">
                        <div className='essential'>
                            <img src={Essential} alt='Essential' />
                        </div>
                        <div className='essential'>
                            <h3>Case Study: UMass Lowell | Paid Digital Media Strategy</h3>
                            <p>UMass Lowell increases conversions while driving down costs with paid digital media strategy</p>
                            <div className='arrow'>
                                <a href='#'>View Case Study</a>
                                <PiArrowCircleRightFill />
                            </div>
                        </div>
                        <div className='essential'>
                            <p>Related Articles:</p>
                            <span>PPC Agency Audit – How to Choose a PPC Partner</span>
                            <span>Pay-Per-Click Marketing: A ‘Nuts & Bolts’ Guide</span>
                        </div>
                    </div>
                </div>
            </section>

            <OurClient/>
            <Footer />
        </>
    )
}

export default PpcManagement