import React from "react";
import "./reactnative.css";
import Navbar1 from "../../Component/Navbar/Navbar1";
import Navbar2 from "../../Component/Navbar/Navbar2";
import NativeApp from "../../Component/Assest/pages/reactnative_img/native-mobile-app.jpg";
import rightArrow from "../../Component/Assest/pages/reactnative_img/bullet_arrow_blue.svg";

import ReactImg1 from "../../Component/Assest/pages/reactnative_img/Next-vs-react.png"
import ReactImg2 from "../../Component/Assest/pages/reactnative_img/React-Native.png"
import ReactImg3 from "../../Component/Assest/pages/reactnative_img/React-Vs-native-development.jpg"
import ReactImg4 from "../../Component/Assest/pages/reactnative_img/hire-React-developer.jpg"
import ReactImg5 from "../../Component/Assest/pages/reactnative_img/native-vs-fluter.jpg"
import Footer from "../../Component/Footer/Footer";
import OurClient from "../../Component/OurClient/OurClient";

const ReactNative = () => {
  const NativeContent = [
    {
      title: "React Native Consulting",
      delay: "300",
      desc: "From design to rollout, our experts take you through the consultation of product concepts and requirements. We analyze client-provided information to create a strategic plan for achieving your corporate objectives.",
    },
    {
      title: "React Native Application Development",
      delay: "300",
      desc: "Through advanced methodologies, we translate your business needs into efficient mobile apps. Our innovative approach ensures seamless integration, intuitive design, and ongoing support for optimal performance and user satisfaction. ",
    },
    {
      title: "React Native iOS and Android Applications",
      delay: "300",
      desc: "Build native apps for iOS and Android devices using React Native to optimize your mobile development processes. Unlike many hybrid apps, we can help you achieve an exceptional user interface (UI) and feature-rich functionality.",
    },
    {
      title: "Integration and Migration Services",
      delay: "400",
      desc: "We offer integration and migration services, with hands-on experience in both. Our team works on a wide variety of applications using React Native. We also migrate apps from other platforms without losing any data. ",
    },
    {
      title: "React Native UI/UX & Development",
      delay: "400",
      desc: "At Softweb, we employ the entire React Native library to provide the most engaging and creative user experience for your business apps. We are driven to deliver real-time experiences across several devices to ensure UI/UX gaps are filled. ",
    },
    {
      title: "Support and Consulting Services",
      delay: "400",
      desc: "After every release of a product, the necessity for care and control is crucial. We provide post-launch support and maintenance to ensure that all bugs are resolved, and your product is functioning as expected. ",
    },
  ];

  const Imagecontent = [
    {
      image: ReactImg1,
      delay: "400",
      button: "Blog",
      href:"/blog",
      Heading: "Next.js Vs React: Why to choose Next.js in 2024?"
    },
    {
      image: ReactImg2,
      delay: "400",
      button: "Blog",
      href:"/blog",
      Heading: "Why businesses should migrate to React Native?"
    },
    {
      image: ReactImg3,
      delay: "400",
      button: "Blog",
      href:"/blog",
      Heading: "React Native vs Native: What to choose for mobile development"
    },
    {
      image: ReactImg4,
      delay: "300",
      button: "Blog",
      href:"/blog",
      Heading: "Are you planning to hire React Native developers to build your mobile applications?"
    },
    {
      image: ReactImg5,
      delay: "300",
      button: "Blog",
      href:"/blog",
      Heading: "React Native vs Flutter - Choose your Hero React native, Flutter"
    },
  ]

  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <section className="native_main_section">
        <div className="native_bg"></div>

        <div className="react_native_services">
          <h2>React Native Development Acs Consulting Services</h2>
          <div className="react_native_flex">
            {NativeContent.map((item, index) => (
              <div className="react_native_cards" key={index}
                data-aos="fade-up"
                data-aos-duration="4000"
                data-aos-delay={item.delay}>
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="native_app_content">
          <div className="native_app_content_flex">
            <div className="content" data-aos="fade-right">
              <h3>
                A mobile app built using React acts as a gateway for air quality
                monitoring
              </h3>
              <p>
                Our client strives to solve the air pollution crisis using a smart
                band that allows users to track the air quality in the surrounding
                environment. They wanted a cloud-based mobile solution to
                facilitate wearable device manufacturers and app developers with
                these features on a larger scale. We suggested:
              </p>
              <ul>
                <li>
                  <img src={rightArrow} alt="rightArrow" />A custom solution using
                  Avnet’s IoTConnect® platform
                </li>
                <li>
                  <img src={rightArrow} alt="rightArrow" />
                  Mobile app as a gateway
                </li>
                <li>
                  <img src={rightArrow} alt="rightArrow" />A user dashboard that
                  collects real-time data
                </li>
                <li>
                  <img src={rightArrow} alt="rightArrow" />
                  Dataflow to IoTConnect® for better AQI management
                </li>
              </ul>
            </div>
            <div className="image" data-aos="fade-left">
              <img src={NativeApp} alt="NativeApp" />
            </div>
          </div>
        </div>

        <div className="native_resources_section">
          <h1>Acs Consulting Resources</h1>
          <div className="native_resources_flex">
            {
              Imagecontent.map((items, index) => (
                <div className="native_resources_cards" key={index}
                  data-aos="fade-up"
                  data-aos-duration="4000"
                  data-aos-delay={items.delay}>
                  <div className="resources_img">
                    <img src={items.image} />
                  </div>
                  <div className="resources_text">
                    <a href={items.href}>{items.button}</a>
                    <h3>{items.Heading}</h3>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>

      <OurClient/>
      <Footer />
    </>
  );
};

export default ReactNative;
