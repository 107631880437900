import React, { useEffect } from 'react';
import './BenefitSection.css';
import expertise1 from "../Assest/img3.jpeg"
import expertise2 from "../Assest/img4.jpeg"
import expertise3 from "../Assest/Fourth2.jpeg"
import AOS from 'aos';
import "aos/dist/aos.css"


const BenefitSection = () => {

  useEffect(() => {
    AOS.init();
  }, [])

  const benefits = [
    {
      icon: expertise1,
      delay:"300",
      title: 'Experienced Team',
      description: 'We are well known for using agile methodology and have an experienced and professional team.',
    
    },
    {
      icon: expertise2,
      delay:"400",
      title: 'Tech Support',
      description: 'You can contact our support team anytime. We are always ready to help you out.',
      img:expertise2
    },
    {
      icon: expertise3,
      delay:"500",
      title: 'Security',
      description: 'We ensure your app is 100% safe with data confidentiality when using ACS',
    },
  ];

  return (
    <div className="benefit-section">
      <h2>Additional Benefits When You Hire Dedicated Developer From ACS</h2>
      <div className="benefit-grid">
        {benefits.map((benefit, index) => (
          <div key={index} className="benefit-card" data-aos="fade-up" data-aos-delay={benefit.delay}>
            <div className="benefit-icon">
              <img src={benefit.icon} alt={benefit.title} />
            </div>
            <div className="benefit-content">
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitSection;