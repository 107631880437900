import React from 'react'
import "./odoodigital.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import OdooIcov1 from "../../Component/Assest/pages/odoo_img/Automate-icon.webp"
import OdooIcov2 from "../../Component/Assest/pages/odoo_img/campaign-icon.png"
import OdooIcov3 from "../../Component/Assest/pages/odoo_img/ikona-icon.png"
import OdooIcov4 from "../../Component/Assest/pages/odoo_img/monetize-icon.png"
import Footer from '../../Component/Footer/Footer'
import { Accordion } from 'react-bootstrap'
import OurClient from '../../Component/OurClient/OurClient'

const OdooDigital = () => {

  const OdooContent = [
    {
      image: OdooIcov1,
      delay: "500",
      title: "Understanding Project Scope",
      desc: "Before we embark with the project planning and execution, we acquire a full-on report on its scope through our Odoo website development consultants. Our experts have a thorough discussion with our clients to understand the business pain points and challenges. Based on these, we further documents the project scope, tasks, deliverables, budget, time frame, and other variables.",
    },
    {
      image: OdooIcov2,
      delay: "500",
      title: "Analyzing Requirements",
      desc: "Requirement analysis is one of the most important phases to successfully deliver flawless Odoo websites. Here, we conduct a thorough data analysis and reporting of various domains, including HR, sales, finances, operations, production, management, and so on. We also segmentalize these domains into smaller areas and focus on them to gather the in-depth business requirements to be used later on in SDLC.",
    },
    {
      image: OdooIcov3,
      delay: "400",
      title: "Designing UI & Developing Software",
      desc: "At Silent InfoTech, we follow an agile methodology for Odoo website development process. In this, we first close requirement gathering and divide it into smaller segments. Each segment is meant to be delivered within a sprint where a strict time window is followed. The number of requirements to be development in a sprint depends on the client budget, requirements, and the story points. Our designers and developers follow properly orchestrated roadmap to ensure the development cycle is completed as per the original plans without any delays in deliveries.",
    },
    {
      image: OdooIcov4,
      delay: "400",
      title: "Quality Analysis & Testing",
      desc: "We conduct a thorough cycle of quality analysis and testing to ensure the developed Odoo website performs as per the initial requirements. Furthermore, we re-modify and scale the website features and components based on the testing results, fix the bugs, and optimize the performance. Our testers are experienced professionals who adopt different approaches, including User Acceptance Testing, System and Integration Testing, volume and load testing, performance testing, and many more.",
    },
  ]

  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <section className='odoo_digital_section'>
        <div className='odoo_bg'></div>

        <div className='odoo_digital_boxes'>
          <h1>Approach We follow for Odoo <br /> Website Development</h1>
          <div className='odoo_digital_flex'>
            {
              OdooContent.map((item, index) => (
                <div className='odoo_digital_cards' key={index} data-aos="fade-up"
                  data-aos-duration="4000"
                  data-aos-delay={item.delay}>
                  <img src={item.image} alt='' />
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                </div>
              ))
            }
          </div>
        </div>

        <div className='back_content' data-aos="fade-up"
          data-aos-duration="4000">
          <h4> Let's transform your vision into a user-friendly, feature-rich website. Start discussing your Business website requirements with us now!</h4>
          <a href=''>Speak to Experts</a>
        </div>

        <div className='faq_section'>
          <h5>FAQs Related to Odoo Website Development</h5>
          <Accordion>
            <Accordion.Item eventKey="0" className='faq' data-aos="fade-up"
              data-aos-duration="9000">
              <Accordion.Header>What does Odoo do?</Accordion.Header>
              <Accordion.Body>
                The work that Odoo achieves is free and open-source and a suite of business applications that allows you to perform anything, from creating a website to improving customer relationships as well as stock control. It's easy to customize and very straightforward for someone to use.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className='faq' data-aos="fade-up"
              data-aos-duration="8000">
              <Accordion.Header>Why use Odoo for website building?</Accordion.Header>
              <Accordion.Body>
                What makes Odoo integrate your website with other business tools is that makes all those tasks of tracking sales, managing stocks, and dealing with the customers become a lot easier. Its structure allows it to grow and develop as your business grows.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className='faq' data-aos="fade-up"
              data-aos-duration="7000">
              <Accordion.Header>What kinds of websites could I make using Odoo?</Accordion.Header>
              <Accordion.Body>
                With it, one can implement very different types of websites: online store, corporate portal, blog, or collection of works.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className='faq' data-aos="fade-up"
              data-aos-duration="6000">
              <Accordion.Header>Does one need coding knowledge to make a website using Odoo?</Accordion.Header>
              <Accordion.Body>
                Not at all. Any non-coder can create an Odoo website easily, even for a developer, since there are drag-and-drop options and pre-existing templates, so you can tailor features not exactly knowing much code.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className='faq' data-aos="fade-up"
              data-aos-duration="5000">
              <Accordion.Header>Does Odoo support multi-lingual web pages?</Accordion.Header>
              <Accordion.Body>
                Absolutely. Odoo makes it extremely easy to create multiple language content-indeed, in all languages and geographies.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className='faq' data-aos="fade-up"
              data-aos-duration="4000">
              <Accordion.Header>Which functionalities of e-commerce sales does Odoo offer?</Accordion.Header>
              <Accordion.Body>
                Odoo allows you to develop an online sale business website and give a structured online sales platform through which to build your e-commerce website, handle your stock and transactions, and process orders efficiently and seamlessly.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6" className='faq' data-aos="fade-up"
              data-aos-duration="7000">
              <Accordion.Header>Does Odoo support Search Engine Optimization (SEO)?</Accordion.Header>
              <Accordion.Body>
                Yes, the application is supplied with integrated SEO modules to make your website more visible in search engines. That is to say, you can easily manipulate meta tags, URLs, and alt texts to the images for ranking the site better.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7" className='faq' data-aos="fade-up"
              data-aos-duration="6000">
              <Accordion.Header>What hosting options are available for Odoo websites?</Accordion.Header>
              <Accordion.Body>
                You can host your Odoo website either on the cloud services offered by Odoo or on-site servers or through third-party hosting. Silent Infotech will guide you on each of the hosting choices to ensure your website runs well .
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8" className='faq' data-aos="fade-up"
              data-aos-duration="5000">
              <Accordion.Header>What is the available support for Odoo websites?</Accordion.Header>
              <Accordion.Body>
                The supports which Odoo website offers are two types - community support through forums and documentation and, on the other hand, professional support from certified partners like Silent Infotech is available and can even hire developers for custom website development.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9" className='faq' data-aos="fade-up"
              data-aos-duration="6000">
              <Accordion.Header>Can the Odoo website be connected to any application?</Accordion.Header>
              <Accordion.Body>
                As mentioned above, Odoo site supports all types of integration that may include the payment systems, shipping services, and the tools for CRM. You also create your custom integrations according to your specific requirements with the help of developers from Silent Infotech.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10" className='faq' data-aos="fade-up"
              data-aos-duration="5000">
              <Accordion.Header>What costs am I to incur while building an Odoo website?</Accordion.Header>
              <Accordion.Body>
                Cost is varies based on your needs, which may be the number of features, customizations, and hosting options. Connect with Silent Infotech team to learn more.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11" className='faq' data-aos="fade-up"
              data-aos-duration="7000">
              <Accordion.Header>Is Odoo good for small business?</Accordion.Header>
              <Accordion.Body>
                Certainly! Odoo is designed to work for businesses of all sizes. Its flexible approach means that small businesses can start with only what they need and add more as they grow.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12" className='faq' data-aos="fade-up"
              data-aos-duration="6000">
              <Accordion.Header>How long does it take to build a website on Odoo?</Accordion.Header>
              <Accordion.Body>
                The time frame for building a website on Odoo can be very short as well as long since the complexity of the site plus the specific requirements that are contained in the site can be very different.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>

      <OurClient/>
      <Footer />
    </>
  )
}

export default OdooDigital