import React from 'react';
import { FaApple, FaAndroid, FaDesktop } from 'react-icons/fa'; // Importing icons from react-icons
import './AppDevelopmentSection.css'; // Import custom styles
import { Link } from 'react-router-dom';

const services = [
  {
    icon: <FaApple size={40} />,
    title: 'IOS App Development',
    delay:"400",
    description: 'Smart consultation, IoT, UX, AIs, Bots, and other technologies are synergized to give you the best solutions.',
  },
  {
    icon: <FaAndroid size={40} />,
    delay:"500",
    title: 'Android App Development',
    description: 'Native Android end-to-end app development for smart wearables, smartphones, and tablets with app upgrade & maintenance services.',
  },
  {
    icon: <FaDesktop size={40} />,
    delay:"600",
    title: 'Web App Development',
    description: 'User-friendly, flexible, and cost-effective web app development solutions compatible with a wide array of operating systems and devices.',
  },
  {
    icon: '📱',
    delay:'400',
    title: 'Digital Transformation',
    description: 'Digital transformation is a strategic initiative that incorporates digital technology across all areas of an organization.',
  },
  {
    icon: '🌐',
    delay:'500',
    title: 'Web Development',
    description: 'A website with seamless flow, visual delights, and enhanced and immersive user experience.',
  },
  {
    icon: '🛒',
    delay:'600',
    title: 'Data Management',
    description: 'Data management is the process of collecting, organizing, and using data in a secure, efficient, and cost-effective manner',
  },
  {
    icon: '🔗',
    delay:'400',
    title: 'Web Portal',
    description: 'Bridge the gap with your customers between your business and customers, vendors, and partners.',
  },
  {
    icon: '📊',
    delay:'500',
    title: 'Digital Marketing',
    description: 'Comprehensive and all-inclusive digital marketing services that transform your ideas into a reality.',
  },
  {
    icon: '📝',
    delay:'600',
    title: 'Dedicated Resources',
    description: 'Every project, big or small, is important; hence, ACS dedicates one resource per client.',
  }
];

const AppDevelopmentSection = () => {
  return (
    <div className="app-development-section">
      <h2 className="heading">Explore Our Dynamic Range of Development Solutions</h2>
      
      <div className="services-div" style={{maxWidth:"1124px",margin:"0 auto"}}>
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card"  style={{ margin:"auto"}}key={index} data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay={service.delay}>
            <div className="service-icon">{service.icon}</div>
            {/* Title is now just plain text */}
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
            {/* Removed the Read More link */}
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default AppDevelopmentSection;
