import React from "react";
import { FaPhoneAlt, FaEnvelope, FaClock, FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";
import "./OfficeSection.css";
import india from "../Assest/india.png";
import denmark from "../Assest/denmark.jpg";
import us from "../Assest/us.webp";

const OfficesSection = () => {
  return (
    <div className="offices-section">
      <div className="office" data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine" data-aos-delay="600">
        <h4><img src={india} alt="India Flag" /> Head Office</h4>
        <p><FaMapMarkerAlt /> #B06, H-169, Sector 63 Noida, U.P. 201301, India</p>
        <p><FaPhoneAlt /> <a href="tel:+911204465842">0120 4465842</a></p>
        <p><FaPhoneAlt /> <a href="tel:+919315652636">+91 93156 52636</a></p>
        <p><FaWhatsapp /> <a href="https://wa.me/919315652636" target="_blank" rel="noopener noreferrer">WhatsApp</a></p>
        <p><FaEnvelope /> <a href="mailto:info@acstechconsulting.com">info@acstechconsulting.com</a></p>
        <p><FaClock /> Opening Hours: 10:00 - 18:00</p>
      </div>
      <div className="office" data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine" data-aos-delay="500">
        <h4><img src={denmark} alt="Denmark Flag" /> Denmark Office</h4>
        <p><FaMapMarkerAlt /> Birkhøjvej 7, Kongens Lyngby , 2800, Denmark</p>
        <p><FaPhoneAlt /> <a href="tel:+4591664592">+45 91 66 45 92</a></p>
        <p><FaWhatsapp /> <a href="https://wa.me/4591664592" target="_blank" rel="noopener noreferrer">WhatsApp</a></p>
      </div>
      <div className="office" data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine" data-aos-delay="600">
        <h4><img src={us} alt="US Flag" /> US Office</h4>
        <p><FaPhoneAlt /> <a href="tel:+19453019521">+1 (945) 301-9521</a></p>
        <p><FaWhatsapp /> <a href="https://wa.me/19453019521" target="_blank" rel="noopener noreferrer">WhatsApp</a></p>
      </div>
    </div>
  );
};

export default OfficesSection;
