import React from 'react'
import "./blog-sec.css"
import Navbar1 from '../../Navbar/Navbar1'
import Navbar2 from '../../Navbar/Navbar2'
import OurClient from '../../OurClient/OurClient'
import Footer from '../../Footer/Footer'
import { IoMdHome } from "react-icons/io";
import Blog1 from "../../Assest/pages/blog/blog_seo.png"

const Blog_sec_2 = () => {

  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=9315652636', '_blank');
  };

  const BlogSection = [
    {
      image: Blog1,
      heading: "The Ultimate Guide to SEO for Noida Businesses",
      title: "Yours could be the best restaurant, gift shop, discount store, or computer shop in your area, but you will get customers only when they know about your business.",
      para: "Stay updated with the latest news, tutorials, and insights on web development, technology, and design manage state in your functional components. This tutorial explains the basics with examples Responsive web design ensures your websites look great on any device. Explore techniques to master fluid grids, flexible images, and media queries runtime for building server-side applications. This guide walks you through the setup and core concepts for beginners The latest ES2024 release introduces exciting features, including better asynchronous handling and updated syntax. Dive into the details.",
      para: "Stay updated with the latest news, tutorials, and insights on web development, technology, and design manage state in your functional components. This tutorial explains the basics with examples Responsive web design ensures your websites look great on any device. Explore techniques to master fluid grids, flexible images, and media queries runtime for building server-side applications. This guide walks you through the setup and core concepts for beginners The latest ES2024 release introduces exciting features, including better asynchronous handling and updated syntax. Dive into the details.",
      para: "Stay updated with the latest news, tutorials, and insights on web development, technology, and design manage state in your functional components. This tutorial explains the basics with examples Responsive web design  through the setup and core concepts for beginners The latest ES2024 release introduces exciting features, including better asynchronous handling and updated syntax. Dive into the details.",
      home: "Home",
      anchor1: "/",
      anchor2: "https://acstechconsulting.com/",
      h1: "1. Automated Communication",
      h2: "2. Predictive Analysis for Admission",
      h3: "3. Enhanced Personalization",
    }
  ]


  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className='blog_sectionPage'>
        <div className='blog_bg'></div>
        <div className='blog_container'>
          <div className='blog_section_flexes'>
            {
              BlogSection.map((item, index) => (
                <div className='blog_section_boxes' key={index}>
                  <div className='img' data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <img src={item.image} />
                  </div>
                  <div className='content' data-aos="fade-left"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <h2>{item.heading}</h2>
                    <p>{item.title}</p>
                    <div className='links'>
                      <a href={item.anchor1}><IoMdHome /> {item.home}  </a>
                    </div>
                    <div className="footer-acssocial">
                      <span onClick={handleWhatsAppClick} className="social-icon">
                        <i className="fab fa-whatsapp"></i>
                      </span>
                      <a href="https://www.facebook.com/profile.php?id=61563162418689&mibextid=ZbWKwL" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="https://www.instagram.com/asvconsultingservices?igsh=MTQ0NjVoNGtpbGIwMg==" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/asv-consulting-services/" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>

          {
            BlogSection.map((text, index) => (
              <div className='text' key={index}>
                <div data-aos="fade-up"
                  data-aos-duration="2000">
                  <h3>{text.h1}</h3>
                  <p>{text.para}</p>
                </div>
                <div data-aos="fade-up"
                  data-aos-duration="2000">
                  <h3>{text.h2}</h3>
                  <p>{text.para}</p>
                </div>
                <div data-aos="fade-up"
                  data-aos-duration="2000">
                  <h3>{text.h3}</h3>
                  <p>{text.para}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <OurClient />
      <Footer />
    </>
  )
}

export default Blog_sec_2
