import React from 'react'
import "./brandmanagement.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import Icon1 from "../../Component/Assest/pages/brand_img/icon-1.svg"
import Icon2 from "../../Component/Assest/pages/brand_img/icon-2.svg"
import Icon3 from "../../Component/Assest/pages/brand_img/icon-3.svg"
import BrandAuth from "../../Component/Assest/pages/brand_img/brand_authenticity.webp"
import ResourcesImg1 from "../../Component/Assest/pages/brand_img/brand-safety-blog-1.webp"
import ResourcesImg2 from "../../Component/Assest/pages/brand_img/brand-safety-blog-2.webp"
import ResourcesImg3 from "../../Component/Assest/pages/brand_img/brand-safety-blog-3.webp"
import { FaArrowRightLong } from "react-icons/fa6";
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const BrandManagement = () => {

    const Brandcard = [
        {
            image: Icon1,
            delay: "600",
            title: "Ensure your brand is at its best where it matters most.",
            desc: "Customers turn to social channels for discovery and research before they purchase. Ensuring that your products and services always appear in the best possible light — and reinforce your brand’s authenticity — is crucial.",
        },
        {
            image: Icon2,
            delay: "700",
            title: "Consistency builds trust. Loyalty. And revenue.",
            desc: "Forrester says customers will use 3+ channels in one interaction with a brand. Presenting a consistent brand image at every touchpoint makes it easier for customers to keep your brand top of mind in key purchase moments.",
        },
        {
            image: Icon3,
            delay: "800",
            title: "Protecting your reputation is more important than ever.",
            desc: "86% of people will hesitate to purchase from a business with negative online reviews. A unified governance strategy can help brands manage risk and address challenges before they become crises.",
        },
    ]

    const Resources = [
        {
            delay: "600",
            resourcesImg: ResourcesImg1,
            blog: "Blog",
            Heading: "How to protect your brand reputation in the social media minefield?",
            para1: "Vanipriya Moorthi •",
            para2: " May 24, 2023",
            anchor: "Read Article",
        },
        {
            delay: "700",
            resourcesImg: ResourcesImg2,
            blog: "Blog",
            Heading: "The 3 habits of highly authentic brands",
            para1: "Soleil Kelley  •",
            para2: " March 3, 2022",
            anchor: "Read Article",
        },
        {
            delay: "800",
            resourcesImg: ResourcesImg3,
            blog: "Blog",
            Heading: "5 questions to answer about customer insights",
            para1: "Soleil Kelley  •",
            para2: " March 18, 2022",
            anchor: "Read Article",
        },

    ]

    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section className='brand_manage_section'>
                <div className='brand_bg'></div>

                <div className='brand_manage_content'>
                    <div className='text'>
                        <h1>Brand Management software matters.</h1>
                        <p>75% of millennials’ purchase decisions are influenced by a brand’s social media presence. That makes the ability to deliver a cohesive experience — and drive consistent messaging — across every channel a business-critical necessity.</p>
                    </div>
                    <div className='brand_card_flex'>
                        {
                            Brandcard.map((item, index) => (
                                <div className='brand_cards' key={index} data-aos="fade-up"
                                    data-aos-duration="4000"
                                    data-aos-delay={item.delay}>
                                    <img src={item.image} />
                                    <h3>{item.title}</h3>
                                    <p>{item.desc}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='brand_authenticity_section'>
                    <div className='authenticity_content_flex'>
                        <div className='authenticity_content' data-aos="fade-right" data-aos-duration="4000">
                            <h1>Get the Strategic Handbook on Brand Authenticity</h1>
                            <p>Explore 4 ways consumers perceive a brand’s authenticity — and how it influences preferences and choices. </p>
                            <p>Identify critical challenges that keep many brands from achieving authenticity. </p>
                            <p>Learn how a unified approach to analyzing the voice of your customers surfaces actionable insights that drive more authentic customer experiences.  </p>
                            <p>Learn how to manage and protect your brand through governance and compliance frameworks.</p>
                            <button>DOWNLOAD THE HANDBOOK</button>
                        </div>
                        <div className='authenticity_image' data-aos="fade-left" data-aos-duration="4000">
                            <img src={BrandAuth} alt='Brand_authenticity' />
                        </div>
                    </div>
                </div>

                <div className='brand_resources'>
                    <h4>Resources</h4>
                    <div className='brand_resources_flex'>
                        {
                            Resources.map((resource, index) => (
                                <div className='resources_cards' key={index} data-aos="fade-up"
                                    data-aos-duration="4000"
                                    data-aos-delay={resource.delay}>
                                    <div className='left'>
                                        <button>{resource.blog}</button>
                                        <h5>{resource.Heading}</h5>
                                        <div className='flex'>
                                            <p className='bold'>{resource.para1}</p>
                                            <p>{resource.para2}</p>
                                        </div>
                                        <a href=''>{resource.anchor}</a>
                                        <FaArrowRightLong />
                                    </div>
                                    <div className='resso_image'>
                                        <img src={resource.resourcesImg} alt='resourcesImg' />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <OurClient />

            <Footer />
        </>
    )
}

export default BrandManagement
