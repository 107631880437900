import React from 'react';
import './OurClient.css';
import logo1 from '../Assest/2.png.webp';
import logo3 from '../Assest/5.png.webp';
import logo4 from '../Assest/6.png.webp';
import logo5 from '../Assest/7.png.webp';
import logo6 from '../Assest/8.png.webp';
import logo7 from '../Assest/10.png.webp';
import logo8 from '../Assest/11.png.webp';
import logo9 from '../Assest/12.png.webp';
import logo10 from '../Assest/13.png.webp';
import logo11 from '../Assest/14.png.webp';
import logo12 from '../Assest/9.png.webp';
// import logo13 from '../Assest/Tata.png';

const OurClient = () => {
  const logos = [logo1, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12 ];

  return (
    <>
      <div className='main_client'>
      <h1 className="client-heading">Our Client</h1>
      <div className="ourclient-container">
        <div className="ourclient">
          {/* Original logos */}
          {logos.map((logo, index) => (
            <img key={index} src={logo} alt={`logo-${index}`} className="clientlogo" />
          ))}
          {/* Duplicate logos for continuous scrolling */}
          {logos.map((logo, index) => (
            <img key={`copy-${index}`} src={logo} alt={`logo-${index}-copy`} className="clientlogo" />
          ))}
        </div>
      </div>
      </div>
    </>
  );
};

export default OurClient;
