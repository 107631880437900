import React from 'react'
import "./blog.css"
import Navbar1 from '../../Navbar/Navbar1'
import Navbar2 from '../../Navbar/Navbar2'
import Footer from '../../Footer/Footer'
import OurClient from '../../OurClient/OurClient'
import AcsLogo from "../../Assest/1tt.jpeg"
import Blog3 from "../../Assest/pages/reactnative_img/React-Vs-native-development.jpg"
import Blog4 from "../../Assest/pages/reactnative_img/hire-React-developer.jpg"
import Blog5 from "../../Assest/pages/reactnative_img/native-vs-fluter.jpg"
import Blog6 from "../../Assest/pages/blog/blog_sec.jpg"
import Blog7 from "../../Assest/pages/blog/blog_seo.png"

const Blog = () => {
    
    const BlogSection = [
        {
            logo:AcsLogo,
            image:Blog6,
            heading:"What Is The Objective Of CodeIgniter?",
            title:"Every programming language has some benefits over its predecessors and CodeIgniter is no exception to this rule. It is the most powerful PHP framework based ",
            anchor:"Read More",
            href:"/blog-objective",
            delay:"700",
        },
        {
            logo:AcsLogo,
            image:Blog7,
            heading:"The Ultimate Guide SEO for Noida Businesses",
            title:"Yours could be the best restaurant, gift shop, discount store, or computer shop in your area, but you will get customers only when they know about your business.",
            anchor:"Read More",
            href:"/seo-noida-businesses",
            delay:"600",
        },
        {
            logo:AcsLogo,
            image:Blog3,
            heading:"React Native vs Native: What to choose for mobile development",
            title:"Also, knowledge of key metrics is necessary to create a well-structured marketing strategy. Evaluating",
            anchor:"Read More",
            href:"/blog-mobile-development",
            delay:"500",
        },
        {
            logo:AcsLogo,
            image:Blog4,
            heading:"Are you planning to hire React Native developers to build your mobile applications?",
            title:"Also, knowledge of key metrics is necessary to create a well-structured marketing strategy. Evaluating",
            anchor:"Read More",
            href:"/blog-native-development",
            delay:"700",
        },
        {
            logo:AcsLogo,
            image:Blog5,
            heading:"React Native vs Flutter - Choose your Hero React native, Flutter",
            title:"Also, knowledge of key metrics is necessary to create a well-structured marketing strategy. Evaluating",
            anchor:"Read More",
            href:"/blog-fluter-development",
            delay:"600",
        },
        {
            logo:AcsLogo,
            image:Blog6,
            heading:"What Is The Objective Of CodeIgniter?",
            title:"Every programming language has some benefits over its predecessors and CodeIgniter is no exception to this rule. It is the most powerful PHP framework based ",
            anchor:"Read More",
            href:"/blog-objective",
            delay:"500",
        },
    ]

  return (
    <>
    <Navbar1/>
    <Navbar2/>
      <div className='blog_section'>
        <div className='blog_bg'></div>
        <div className='blog_container'>
            <h1>Blogs</h1>
            <div className='blog_section_flex'>
                {
                    BlogSection.map((item,index)=>(
                        <div className='blog_section_box' key={index} data-aos="fade-up"
                        data-aos-duration="2000" data-aos-delay={item.delay}>
                            <img className='logo' src={item.logo}/>
                            <div className='blog_img'>
                                <img src={item.image}/>
                            </div>
                            <h3>{item.heading}</h3>
                            <p>{item.title}</p>
                            <a href={item.href}>{item.anchor}</a>
                        </div>
                    ))
                }
            </div>
        </div>
      </div>
      <OurClient/>
      <Footer/>
    </>
  )
}

export default Blog
