import React, { useEffect, useRef, useState } from 'react'
import style from "./job.module.css"
import { Link } from 'react-router-dom';
import Navbar1 from '../../Navbar/Navbar1'
import Navbar2 from '../../Navbar/Navbar2'
import Footer from '../../Footer/Footer'
import { FaLinkedinIn } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios"
import {useLocation} from 'react-router-dom'

const Jobs = ({match }) => {

    const engineeringRef = useRef(null);
    const businessRef = useRef(null);
    const qualityRef = useRef(null);

    // Scroll function
    const handleScroll = (sectionRef) => {
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const [engineering, setEngineering] = useState([]);
    const [business, setBusiness] = useState([]);
    const [quality, setQuality] = useState([]);
    const [allJobs, setAllJobs] = useState([]);

    const getJobsData = async()=>{
          try {
             const response = await axios.get('https://api.eduuxpert.com/api/job-posts')
                setAllJobs(response?.data)
          } catch (error) {
             console.log("Comming to error in getjob post")
          }
    }

    useEffect(()=>{
        getJobsData();
    },[])


useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
}, []);
      console.log("allJobs",allJobs);
    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section>
                <div className='web_section'>
                    <h1>Open Positions for Development</h1>
                    <p>We're excited to announce the opportunities currently available on our team! Check out the open positions we’re hiring for development.</p>
                    <Link to="/career" className={style.carrer}>
                        Career
                    </Link>
                </div>

                <div className={style.jibs_section}>
                    <div className={style.web_container}>
                        <div className={style.jobs_div}>
                            <button onClick={() => handleScroll(engineeringRef)}>Engineering</button>
                            <button onClick={() => handleScroll(businessRef)}>Business</button>
                            <button onClick={() => handleScroll(qualityRef)}>Quality Analyst</button>
                            {/* <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>All</button> */}
                        </div>
                        <div className={style.jobs_update}>
                            <p>Stay updated on our latest job openings by following us on LinkedIn!</p>
                            <Link
                                className={style.button_in}
                                to="https://www.linkedin.com/company/asv-consulting-services/posts/?feedView=all"
                            >
                                <FaLinkedinIn className={`${style.icon} button-hover`} />
                                <span className={style.linkdin}>Follow us on LinkedIn</span>
                            </Link>
                        </div>

                        <div className={style.jobs_category}>
                            <div ref={engineeringRef} className={style.main_section}>
                                <h2>Engineering</h2>
                                <div className={style.jobs_category_flex}>
                                    {allJobs?.data?.map((item, index) => (
                                        <Link to={`/job/${item.id}`}>
                                        <div className={style.jobs_cards} key={index}>
                                            <h5>{item.title}</h5>
                                            <div className={style.context_flex}>
                                                <div className={style.spans_text}>
                                                    <span>{item.location}</span>
                                                    <br />
                                                    <span>{item.type}</span>
                                                </div>
                                                <MdKeyboardArrowRight />
                                            </div>
                                        </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>

                            {/* Business Section */}
                            <div ref={businessRef} className={style.main_section}>
                                <h2>Business</h2>
                                <div className={style.jobs_category_flex}>
                                    {allJobs?.data?.map((item, index) => (
                                        <Link to={`/job/${item.id}`}>
                                        <div className={style.jobs_cards} key={index}>
                                            <h5>{item.title}</h5>
                                            <div className={style.context_flex}>
                                                <div className={style.spans_text}>
                                                    <span>{item.location}</span>
                                                    <br />
                                                    <span>{item.type}</span>
                                                </div>
                                                <MdKeyboardArrowRight />
                                            </div>
                                        </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>

                            {/* Quality Analyst Section */}
                            <div ref={qualityRef} className={style.main_section}>
                                <h2>Quality Analyst</h2>
                                <div className={style.jobs_category_flex}>
                                    {allJobs?.data?.map((item, index) => (
                                         <Link to={`/job/${item.id}`}>
                                        <div className={style.jobs_cards} key={index}>
                                            <h5>{item.title}</h5>
                                            <div className={style.context_flex}>
                                                <div className={style.spans_text}>
                                                    <span>{item.location}</span>
                                                    <br />
                                                    <span>{item.type}</span>
                                                </div>
                                                <MdKeyboardArrowRight />
                                            </div>
                                        </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Jobs
