import React from 'react'
import "./phpdevelopment.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import Hadephone from "../../Component/Assest/pages/php_img/headphones.png"
import Diamond from "../../Component/Assest/pages/php_img/diamond.png"
import Midalist from "../../Component/Assest/pages/php_img/medalist.png"
import Gear from "../../Component/Assest/pages/php_img/gear.png"

import WebDesining from "../../Component/Assest/pages/php_img/web-design.png"
import AppDevelop from "../../Component/Assest/pages/php_img/app-development.png"
import OnlineShop from "../../Component/Assest/pages/php_img/online.png"
import DataDevelop from "../../Component/Assest/pages/php_img/data2.png"
import SEO from "../../Component/Assest/pages/php_img/seo.png"
import Footer from '../../Component/Footer/Footer'
import Accordion from 'react-bootstrap/Accordion';
import OurClient from '../../Component/OurClient/OurClient'
import { Link } from 'react-router-dom'

const PhpDevelopment = () => {

  const cardContent = [
    {
      images: Hadephone,
      delay:"400",
      title: "IT support & helpdesk",
      description: "Reliable IT support and helpdesk solutions tailored for seamless operations, are brought to you by iQSetters.",
    },
    {
      images: Diamond,
      delay:"400",
      title: "User Experience",
      description: "Elevate your digital journey with us, where intuitive user experiences redefine the way, you interact with technology.",
    },
    {
      images: Midalist,
      delay:"300",
      title: "Analytic Solutions",
      description: "Uncover actionable insights and drive data-driven decisions with iQSetters' advanced analytic solutions.",
    },
    {
      images: Gear,
      delay:"300",
      title: "Business Planning",
      description: "Chart your path to success with iQSetters – Your partner in strategic business planning.",
    },
  ]

  const solutionCars = [
    {
      imageIcon:WebDesining,
      delay:"500",
      text:"Website Design and Development",
      href:"/wordpress",
      desc:"At IQSetters, we specialize in crafting bespoke digital experiences that captivate audiences and drive results...",
      text2:"READ MORE",
    },
    {
      imageIcon:AppDevelop,
      delay:"500",
      text:"Mobile App Development",
      href:"/android-development",
      desc:"Reach a broader audience with cross-platform mobile app development. Our team leverages the latest technologies to build apps ...",
      text2:"READ MORE",
    },
    {
      imageIcon:OnlineShop,
      delay:"500",
      text:"Email Marketing",
      href:"/email-marketing",
      desc:"Beyond the norm, IQSetters provides outstanding E-commerce and Content Management System (CMS) services that transform...",
      text2:"READ MORE",
    },
    {
      imageIcon:DataDevelop,
      delay:"400",
      text:"PPC Services",
      href:"/ppc-services",
      desc:"Recognizing your online presence is essential for success in the digital age. Get unparalleled insight into the functionality...",
      text2:"READ MORE",
    },
    {
      imageIcon:SEO,
      delay:"400",
      text:"Search Engine Optimization (SEO)",
      href:"/seo",
      desc:"Making an impression in the crowded online environment is crucial. Our Search Engine Optimization (SEO) services aim...",
      text2:"READ MORE",
    },
  ]

  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className='php_develop_section'>
        <div className='php_bg_img'></div>
        <div className='php_develop_content_section'>
          <div className='php_develop_content_flex'>
            <div className='php_develop_content' data-aos="fade-right">
              <h6>Asv Consulting Services</h6>
              <h1>We provide IT Acs Consulting Services Private Limited</h1>
              <p className='para'>Since 2020, iQSetters has been a dedicated provider of customized solutions for various industries, with a strong emphasis on real estate. We stand out by offering both custom and platform-based solutions, ensuring your specific needs are met. Choose iQSetters for a unique and flexible approach to address your business requirements.</p>

              <Accordion defaultActiveKey="0">
                <Accordion.Item className='php_faq' eventKey="0">
                  <Accordion.Header className='heading'>Creative App Design</Accordion.Header>
                  <Accordion.Body className='para'>
                    Dive into a world of boundless imagination and artistic expression with our Creative App Design. Seamlessly blending form and function, our app is crafted to inspire innovation, foster creativity, and elevate your digital experience.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className='php_faq' eventKey="1">
                  <Accordion.Header className='heading'>Technologically Sound Apps</Accordion.Header>
                  <Accordion.Body className='para'>
                    Take the lead in innovation with our Apps That Are Technologically Sound. Our apps are expertly designed to take advantage of the most recent developments and effectively integrate cutting-edge technology for an unmatched user experience.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className='php_faq' eventKey="3">
                  <Accordion.Header className='heading'>Apps for Social Interaction</Accordion.Header>
                  <Accordion.Body className='para'>
                    Introducing our Apps for Social Interaction, which bring in a new era of connectivity. These apps, which are crafted with the perfect intention to unite people, transform how we communicate, share, and prosper in the digital age.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className='php_develop_img_content'>
              {
                cardContent.map((card,index) =>(
                  <div className='cards_flex' key={index} data-aos="fade-up"
                  data-aos-duration="4000"
                  data-aos-delay={card.delay}>
                    <div className='img'><img src={card.images}/></div>
                    <h3>{card.title}</h3>
                    <p>{card.description}</p>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className='php_solutions'>
              <div className='solutions_text'>
                <h1>Solutions</h1>
                <p><span>iQSetters</span> pioneers comprehensive and unique software solutions designed to streamline operations and empower organizations across a wide range of sectors. From custom development to cutting-edge applications, our team creates solutions that are in line with the changing digital landscape, assuring your business's efficiency and success.</p>
              </div>
              <div className='solutions_boxes'>
                {
                  solutionCars.map((solution, index) =>(
                    <div className='boxes' key={index} data-aos="fade-up"
                    data-aos-duration="4000"
                    data-aos-delay={solution.delay}>
                      <img src={solution.imageIcon}/>
                      <h4>{solution.text}</h4>
                      <p>{solution.desc}</p>
                      <div className='line'>
                      <Link to={solution.href}>{solution.text2}</Link>
                      </div>
                    </div>
                  ))
                }
              </div>
        </div>
      </div>

      <OurClient/>
      <Footer />
    </>
  )
}

export default PhpDevelopment
