import React from 'react'
import "./smodigital.css"
import Navbar2 from '../../Component/Navbar/Navbar2'
import Navbar1 from '../../Component/Navbar/Navbar1'
import Facebook from "../../Component/Assest/pages/smo_img/facebook.png"
import Instagram from "../../Component/Assest/pages/smo_img/Instagram.png"
import Linkdin from "../../Component/Assest/pages/smo_img/LinkedIn.png"
import Youtube from "../../Component/Assest/pages/smo_img/youtube.png"
import Influencer from "../../Component/Assest/pages/smo_img/influencer.png"
import MobileApp from "../../Component/Assest/pages/smo_img/mobile.png"

import Optimization1 from "../../Component/Assest/pages/smo_img/campaign-icon.png"
import Optimization2 from "../../Component/Assest/pages/smo_img/ikona-icon.png"
import Optimization3 from "../../Component/Assest/pages/smo_img/Automate-icon.webp"
import Optimization4 from "../../Component/Assest/pages/smo_img/monetize-icon.png"
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient' 
import SmoService from "../../Component/Assest/pages/smo_img/smo-1.png"

const SmoDigital = () => {

    const SmoContent = [
        {
            image: Facebook,
            delay: "500",
            title: "Facebook Advertisement",
            desc: "Facebook marketing is an efficient and cost-effective tool for digital marketing. We offer Facebook advertising services to reach a broader audience network. We provide a comprehensive plan to reach different customers at varying stages of a sales funnel. We can help you with everything from strategy creation to campaign analysis to assist you in the achievement of your marketing objective whether it be to earn conversions or increase awareness or promote your product or service. We are efficiently researching, monitoring, and acclimatizing online trends to help you with the latest technique of Facebook ad planning and implementation.",
        },
        {
            image: Instagram,
            delay: "600",
            title: "Instagram Marketing",
            desc: "Are you looking for Instagram marketing services? Are you facing challenges in getting desired leads through Instagram ads? We at the King of Digital Marketing can provide the best Instagram marketing service. Instagram is one of the largest social media platforms and has millions of users. So, promoting your services on Instagram can give you enhanced brand awareness and recognition and also help you to stand out. We offer well-organized Instagram marketing strategies to reach your target audience. Our skillful experts with unique proven strategies can help you in content creation and content strategy to make your promotional campaign more effective.",
        },
        {
            image: Linkdin,
            delay: "700",
            title: "LinkedIn Marketing",
            desc: "The Linkedin platform is also very popular. The majority of Linkedin users have college degrees and they expect to find quality content on the platform. It means that you need to make fresh content for the users of Linkedin or else they will move to other sources that offer them valuable content. We at the King of Digital Marketing can help you in content creation to make your Linked ads more effective. Our effective and unique Linked marketing services can help you achieve your marketing goals whether to expand your network or improve brand awareness or get qualified leads.",
        },
        {
            image: Youtube,
            delay: "600",
            title: "YouTube Marketing",
            desc: "Your target audience may prefer watching videos while knowing about your product or service or brand. It has been surveyed that people prefer to watch a video instead of reading a post. Our agency offers the best YouTube marketing services. We at the King of Digital Marketing have expert YouTube marketers who apply their skills and experience to promote your product or service or brand in the best possible way. We can not only help in video creation but also assist you in video SEO to enhance views and likes. Our other YouTube marketing services include running YouTube ads, video syndication, and distribution, and video description copywriting.",
        },
        {
            image: Influencer,
            delay: "500",
            title: "Influencer Marketing",
            desc: "Influencer marketing services are other social media services that we offer. The King of Digital Marketing is the leading influencer marketing agency that helps influencers to connect with Top Brands for their brand promotion. Our influencer marketing experts connect the top content creators, social media influencers, and bloggers with brands and help you to run remarkable influencer marketing campaigns. We as an Influencer Marketing company reach out to brands and help you to Build Professional relationships with them on your behalf.",
        },
        {
            image: MobileApp,
            delay: "700",
            title: "App Installation Ads",
            desc: "App installation ad services are another social media service that is offered by the leading digital marketing agency, the King of Digital Marketing. We understand the importance of marketing mobile apps as your proficient app marketing partner. Our skilled mobile application marketing experts focus on customer engagement, promotions, sales, and support for achieving the best results. We can help you with app store optimization and the right targeting so that your app appears to the right people at the right time.",
        },
    ]

    const Optimization = [
        {
            optimiz: Optimization1,
            delay: "400",
            title2: "Brand Recognition ",
            description: "Getting your brand talked about by audiences is one of the most crucial and important aspects of our SMO services. Once they see your business active on certain social media platforms, they tend to believe in your business, and eventually end up as your customers. We help you to devise strategies that are relevant and engaging for your business and help you in achieving the best possible results in the least time. ",
        },
        {
            optimiz: Optimization2,
            delay: "500",
            title2: "Better SEO Results",
            description: "A successful social media campaign plays a huge part in ranking a business on search engine result pages. This means that SMO contributes to a company’s SEO tactics as well. The Banyanbrain SMO team adeptly works towards creating attractive content to help you get more engagement and shares from your target audience. By incorporating keywords in bio, profiles, and links, we improve the SEO as well as SMO results for your business to get a stark standing in your market. ",
        },
        {
            optimiz: Optimization3,
            delay: "600",
            title2: "Improved ROI ",
            description: "Banyanbrain helps you with the objective of generating revenue for your business. We include paid advertising and run paid ad campaigns as per the SMO strategy and help create brand awareness for your business. With paid ads and SMO campaigns, we increase your client base as a whole. When the consumers see your social media profiles on social media platforms they believe your business is credible, thus your revenue rate keeps growing to the maximum. ",
        },
        {
            optimiz: Optimization4,
            delay: "700",
            title2: "More goals, Less Time",
            description: "If you are looking for efficiency and time management, then we are happy to tell you that Banyanbrain believes in providing you better results in the lesser time to market as timely delivery of every project is our first and foremost priority. We provide you with calculated and productive outcomes for your company by meeting all deadlines. With us, achieving your businesses’ end goals will not be just a dream as we help you achieve all the objectives with maximum ease. ",
        },
    ]

    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section className='smo_digital_section'>
                <div className='smo_digital_bg'></div>

                <div className='smo_socail_media'>
                    <div className='text'>
                        <h1>Services Offered For Social Media</h1>
                        <p>The King of Digital Marketing also offers social media services which are a type of digital marketing through social media channels. Social media marketing includes running paid ads on various popular social media platforms such as Facebook, Twitter, Instagram, LinkedIn, and Pinterest. We will also help you in promoting and advertise your brands and inspire sales through the social channels frequently used by users by strategizing your social media posts. At present, the power of social media cannot be ignored while promoting your brand. As the majority of people spend a lot of time on social media, promoting a brand or product or service can reach the maximum number of people. Social media channels offer plenty of opportunities to marketers, the King of Digital Marketing grabs these opportunities efficiently and promotes your brand in the best possible way. Here is the list of social media services.</p>
                    </div>
                    <div className='smo_socail_media_flex'>
                        {
                            SmoContent.map((item, index) => (
                                <div className='smo_socail_cards' key={index}
                                    data-aos="fade-up"
                                    data-aos-duration="4000"
                                    data-aos-delay={item.delay}>
                                    <img src={item.image} />
                                    <h3>{item.title}</h3>
                                    <p>{item.desc}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="smo_services">
                <div className='smo_container'>
                <div className='text'>
                        <h1>SMO Services</h1>
                       <p className='para'>Boost Your Online Visibility with Expert SMO Services in Delhi. Harness the power of Social Media Optimization (SMO) with ThreeG Logic – Your Reliable SMO Company in Noida sector 63</p>
                    </div>
                    <div className='smo_services_flex'>
                        <div className='smo_services_left'>
                            <h2>Why SMO Matters for Your <br/> Business</h2>
                            <p>In today’s fast-paced digital world, Social Media Optimization (SMO) is the key to elevating your brand’s visibility. At ThreeG Logic, a premier SMO company in Delhi, we specialize in building impactful social media strategies that help you engage effectively with your target audience and drive meaningful results.</p>
                        </div>
                        <div className='smo_services_right'>
                            <img src={SmoService} alt=''/>
                        </div>
                    </div>
                </div>
                </div>

                <div className='smo_optimization_section'>
                    <div className='text'>
                        <h1>Why do you need a Social Media Optimization Company?</h1>
                        <p>A social media presence is essential for every business in today’s digitally advanced world. Banyanbrain is the top social media optimization agency that helps you to have highly engaging social media platforms. Having a distinguished social media presence helps to create brand awareness on many different social media platforms like Facebook, Instagram, LinkedIn, among many others.</p>
                    </div>
                    <div className='smo_optimization_flex'>
                        {
                            Optimization.map((optimizing, index) => (
                                <div className='smo_optimization_cards' key={index} data-aos="fade-up"
                                data-aos-duration="4000"
                                data-aos-delay={optimizing.delay}>
                                    <img src={optimizing.optimiz} />
                                    <h3>{optimizing.title2}</h3>
                                    <p>{optimizing.description}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <OurClient/>
            <Footer />
        </>
    )
}

export default SmoDigital
