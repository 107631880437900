import React from 'react'
import "./digitalseo.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import SeoIcon1 from "../../Component/Assest/pages/seo_img/seo-icon-1.png"
import SeoIcon2 from "../../Component/Assest/pages/seo_img/seo-icon-2.png"
import SeoIcon3 from "../../Component/Assest/pages/seo_img/seo-icon-3.png"
import SeoIcon4 from "../../Component/Assest/pages/seo_img/seo-icon-4.png"
import ChennaiImg from "../../Component/Assest/pages/seo_img/digital-chennai.jpg"
import { PiArrowBendDoubleUpRightFill } from "react-icons/pi";
import DrBalam from "../../Component/Assest/pages/seo_img/dr-balammurli.jpg"
import Mrlucky from "../../Component/Assest/pages/seo_img/mr-lucky.jpg"
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const DigitalSeo = () => {

    const Digitalservices = [
        {
            image: SeoIcon1,
            delay:"700",
            title: "Search Engine Marketing",
            href:"",
            desc: "We optimize sites to bring you striking conversions with the help of impressive traffic.",
            anchor: "Read More",
        },
        {
            image: SeoIcon2,
            delay:"700",
            title: "Social Media Marketing",
            href:"/smm",
            desc: "We customize unique social media marketing strategies to make your brand soar up high!",
            anchor: "Read More",
        },
        {
            image: SeoIcon3,
            delay:"700",
            title: "Content Marketing",
            href:"/content-writing",
            desc: "Let us help you attract and retain more web traffic with ace content that sells!",
            anchor: "Read More",
        },
        {
            image: SeoIcon4,
            delay:"700",
            title: "PPC Services",
            href:"/ppc-services",
            desc: "We draw powerful insights from virtually non-existent data to spruce up your strategies.",
            anchor: "Read More",
        },
    ]

    const ChennaiSeo = [
        {
            Heading: "No Fixed SEO Or Digital Marketing Packages",
            content: "We understand that each website has customized needs and adhere to it.",
        },
        {
            Heading: "Secure And Safe",
            content: "We specialize in protecting your Website Security & Digital Assets.",
        },
        {
            Heading: "We Offer You A Full-On Digital Marketing Package",
            content: "We do not dissociate SEO, SEM, Content Marketing, SMO etc.",
        },
        {
            Heading: "Transparency At Brim",
            content: "You will always be provided up to date information about how your Online Marketing is carried out.",
        },
        {
            Heading: "Accountability",
            content: "We share every piece of information with you with the aid of precise and automated reports.",
        },
        {
            Heading: "Affordable Charges",
            content: "Affordable charges and reasonable rates. No hidden charges!",
        },
    ]

    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section className='digital_seo_section'>
                <div className='digital_seo_bg'></div>

                <div className='digital_services_section'>
                    <div className='text'>
                        <h1>Digital Marketing Services</h1>
                        <p>Eliminate Challenges, Capture Results</p>
                    </div>
                    <div className='digital_services_flex'>
                        {
                            Digitalservices.map((item, index) => (
                                <div className='digital_cards' key={index} data-aos="fade-up"
                                data-aos-duration="2000">
                                    <img src={item.image} alt='' />
                                    <h3>{item.title}</h3>
                                    <p>{item.desc}</p>
                                    <a href={item.href}>{item.anchor}</a>
                                </div>
                            ))
                        }
                    </div>

                </div>

                <div className='digital_services_chennai'>
                    <div className='text'>
                        <h1>Digital Marketing or SEO services in Chennai</h1>
                        <p>Why DigitalSEO is the First & The Best Choice?</p>
                    </div>
                    <div className='digital_services_flex'>
                        <div className='image' data-aos="fade-right" data-aos-duration="4000">
                            <img src={ChennaiImg} alt='ChennaiImg' />
                        </div>
                        <div className='content' data-aos="fade-left" data-aos-duration="5000">
                            {
                                ChennaiSeo.map((chennai, index) => (
                                    <div className='digital_content_flex' key={index}>
                                        <PiArrowBendDoubleUpRightFill />
                                        <div className='textseo'>
                                            <h5>{chennai.Heading}</h5>
                                            <p>{chennai.content}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='seo_do_services'>
                    <div className='text'>
                        <h1>That’s Why We Do SEO</h1>
                        <p>Our Clients - Our Pride</p>
                    </div>
                    <div className='seo_do_flex'>
                        <div className='do_boxes' data-aos="fade-right" data-aos-duration="4000">
                            <div className='img_content'>
                                <img src={DrBalam} alt='dr balammurli' />
                                <div className='content'>
                                    <h6>Dr.G.Balamurali</h6>
                                    <span>Head of Spine Services - Kauvery Hospital</span>
                                    <span>Managing Director - HAMSA REHAB</span>
                                </div>
                            </div>
                            <p>I am very pleased to be a client of Digital SEO for almost a year. The entire team is very dedicated, committed and innovative. I was very impressed by their professionalism in managing my website, so much so that I decided to go ahead and use their services for my other websites as well. You can count me in as a very satisfied customer who is bowled over by their expertise and attention to minute details. I’m very happy with their services and would highly recommend them. I wish them the very best to grow further.</p>
                        </div>
                        <div className='do_boxes' data-aos="fade-left" data-aos-duration="4000">
                            <div className='img_content'>
                                <img src={Mrlucky} alt='mr lucky' />
                                <div className='content'>
                                    <h6>Mr.Lucky</h6>
                                    <span>Proprietor Of Bloom Beauty Academy</span>
                                </div>
                            </div>
                            <p>Team Digital SEO. is the Best web design & SEO company I have seen ever..This is my 2nd year continuing with the team DSEO.. such a  professionals , on commitment & always they will keep you in above expected level...
                            Today my business in No.1 listing (over all chennai) for all the given key words. Customer Service , always ultimate. They always guide you throughout with all the updates.These many year they never given a over commitment. Highly recommended </p>
                        </div>
                    </div>
                </div>
            </section>

            <OurClient/>
            <Footer />
        </>
    )
}

export default DigitalSeo
