import React from 'react'
import "./emailmarketing.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import EmailIcon1 from "../../Component/Assest/pages/email_img/monetize-icon.png"
import EmailIcon2 from "../../Component/Assest/pages/email_img/Automate-icon.webp"
import EmailIcon3 from "../../Component/Assest/pages/email_img/ikona-icon.png"
import EmailIcon4 from "../../Component/Assest/pages/email_img/people-icon.png"
import EmailIcon5 from "../../Component/Assest/pages/email_img/wifi-icon.png"
import EmailIcon6 from "../../Component/Assest/pages/email_img/Automate-icon.webp"
import Campaigns from "../../Component/Assest/pages/email_img/campaigns.webp"
import Campaigns2 from "../../Component/Assest/pages/email_img/campaigns2.webp"
import Campaigns3 from "../../Component/Assest/pages/email_img/campaigns3.webp"
import { FaArrowRightLong } from "react-icons/fa6";
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const EmailMarketing = () => {

    const Email_Marketing = [
        {
            image:EmailIcon1,
            delay: "400",
            title:"Create beautiful campaigns",
            desc:"Pick from three editors to create engaging, responsive campaigns.",
            anchor:"Learn more",
        },
        {
            image:EmailIcon2,
            delay: "500",
            title:"Automate workflows",
            desc:"Build advanced marketing workflows in the intuitive automation builder.",
            anchor:"Learn more",
        },
        {
            image:EmailIcon3,
            delay: "600",
            title:"Manage campaigns and subscribers",
            desc:"Take care of your email list and campaigns on desktop or on the go via the app.",
            anchor:"Learn more",
        },
        {
            image:EmailIcon4,
            delay: "700",
            title:"Monetize your audience",
            desc:"Integrate e-commerce platforms, like Shopify and WooCommerce or Stripe.",
            anchor:"Learn more",
        },
        {
            image:EmailIcon5,
            delay: "800",
            title:"Send targeted campaigns",
            desc:"The key to great campaigns is to send personalized content to every subscriber.",
            anchor:"Learn more",
        },
        {
            image:EmailIcon6,
            delay: "900",
            title:"Continuously improve campaigns",
            desc:"Monitor campaign performance to discover what works best for your business.",
            anchor:"Learn more",
        },
    ] 

  return (
    <>
      <Navbar1/>
      <Navbar2/>
      <section className='email_marketing_section'>
        <div className='email_hero_section'>
            <h6>Email marketing</h6>
            <h1>Grow your business with email</h1>
            <p>Collect subscribers, strengthen customer relationships, automate workflows and monetize your audience with MailerLite’s advanced email marketing features.</p>
            <div className='trial'>
                <button>Start for trail</button>
                <span>Start a 30-day trial of premium features | No card required</span>
            </div>
        </div>

        <div className='email_marketing_card_flex'>
            {
                Email_Marketing.map((email, index) =>(
                    <div className='email_marketing_card' key={index} data-aos="fade-up"
                    data-aos-duration="4000"
                    data-aos-delay={email.delay}>
                        <div className='content'>
                        <img src={email.image}alt=''/>
                        <h3>{email.title}</h3>
                        <p>{email.desc}</p>
                        <a href="#">{email.anchor}</a>
                        </div>
                    </div>
                ))
            }
        </div>

        <div className='email_campaigns_section'>
            <div className='email_campaigns_flex'>
                <div className='campaigns_left' data-aos="fade-right" data-aos-duration="4000">
                    <h1>Create beautiful campaigns</h1>
                    <p>Pick from three editors to create engaging, responsive campaigns. Use our drag & drop editor with interactive content blocks, get more personal with a rich-text email or build custom campaigns using the HTML editor.</p>
                    <div className='arrow_icon_content'>
                        <div className='icon_content'>
                            <h5>Drag & drop editor</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>Rich-text editor</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>Custom HTML editor</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>AI email generator</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>Newsletter templates</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>Free image library</h5>
                            <FaArrowRightLong/>
                        </div>
                        
                    </div>
                </div>
                <div className='campaigns_right' data-aos="fade-left" data-aos-duration="4000">
                    <img src={Campaigns} alt='campaigns'/>
                </div>

                <div className='campaigns_right' data-aos="fade-right" data-aos-duration="4000">
                    <img src={Campaigns2} alt='campaigns'/>
                </div>

                <div className='campaigns_left' data-aos="fade-left" data-aos-duration="4000">
                    <h1>Monetize your audience</h1>
                    <p>Integrate e-commerce platforms, like Shopify and WooCommerce, to embed custom product blocks and create email campaigns based on customers’ buying behaviors. Or monetize your newsletter by selling subscriptions through Stripe on MailerLite landing pages.</p>
                    <div className='arrow_icon_content'>
                        <div className='icon_content'>
                            <h5>Sell digital products</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>E-commerce campaigns</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5> Paid newsletters
                            </h5>
                            <FaArrowRightLong/>
                        </div>
                        
                    </div>
                </div>
                
                <div className='campaigns_left' data-aos="fade-right" data-aos-duration="4000">
                    <h1>Automate workflows</h1>
                    <p>Build advanced marketing workflows in the intuitive automation builder. Use triggers to automatically deliver emails or update custom fields. Or set up RSS campaigns to notify subscribers about new content, and auto-resend campaigns to increase engagement.</p>
                    <div className='arrow_icon_content'>
                        <div className='icon_content'>
                            <h5>Automation</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>RSS campaigns</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>Auto-resend campaigns</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>Facebook custom audiences</h5>
                            <FaArrowRightLong/>
                        </div>
                        <div className='icon_content'>
                            <h5>API documentation</h5>
                            <FaArrowRightLong/>
                        </div>
                    </div>
                </div>
                <div className='campaigns_right' data-aos="fade-left" data-aos-duration="4000">
                    <img src={Campaigns3} alt='campaigns'/>
                </div>
            </div>
        </div>
      </section>

      <OurClient/>
      <Footer/>
    </>
  )
}

export default EmailMarketing