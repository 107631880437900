import React, { useEffect } from 'react';
import "./Carrer.css"; // Import the CSS file
import Footer from '../Footer/Footer';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";
import i1 from '../Assest/1.png'
import i2 from '../Assest/office_celebration.jfif'
import i3 from '../Assest/employees.jpg'
import facebook from "../Assest/icon-facebook.webp"
import instagram from "../Assest/instagram_logo.png"
import linkdin from "../Assest/icon-linkedin.webp"

const JobApplicationForm = () => {

  const schoolTypes = [
    { type: 'Birthday Celebration', image: i1, description: 'Follow us on Facebook to explore what life at Asc techconsulting services is all about!',Img:facebook, anchor:"https://www.facebook.com/profile.php?id=61563162418689" },
    { type: 'We Celebrate', image: i2, description: 'Connect with us on LinkedIn to discover the latest job opportunities!',Img:instagram, anchor:"https://www.instagram.com/asvconsultingservices/" },
    { type: 'Presentation ', image: i3, description: 'Attract, recruit, and onboard top talent to build a high-performing team.',Img:linkdin, anchor:"https://www.facebook.com/profile.php?id=61563162418689"  },
  ];

  const Fun =[
    {heading:"Life at Acs techconsulting services is fun",paragraph:"Life is a collection of unforgettable memories, and at Acs techconsulting services, we make every moment count. Work with fun is our mantra, creating a journey filled with joy and growth. Join us to dive into a world where you can learn, laugh, and thrive!"},
    // {funImg:}
  ]

  useEffect(() => {
      window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        });
  }, []);

  return (
    <>
      <Navbar1 />
      <Navbar2 />
     
      <div className='main_section'>
        <div className='web_section'>
          <h1>Work at Acs techconsulting <br /> Services</h1>
          <p>We design and develop solutions that empower businesses to thrive. Partnering with visionaries like you, we collaborate to craft exceptional, world-class products</p>
          <Link className="button" to="/career-jobs">
            <FaArrowRight className="button-hover" />
            <span className="button-text-hover">View Jobs Position</span>
            <span className="button-text">View Jobs Position</span>
          </Link>
        </div>
        <section className="cardSection">
          <div className="cardGrid">
            {schoolTypes.map((school, index) => (
              <div key={index} className="card">
                <div className="cardInner">
                  <img src={school.image} alt={school.type} className= "cardImage" />
                  <div className="cardContent">
                    <h2 className="cardTitle">{school.type}</h2>
                    <div className="cardDescription">
                      <p>{school.description}</p>
                      <Link to={school.anchor} target='blank'>
                      <img src={school.Img} alt='facebook'/>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
         <div className="full-page">
      <form>
        <h1>Discover Amazing Opportunities Today</h1>

        <div className="form-row">
          <div className="form-column">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" placeholder="Full Name" />

            <label htmlFor="mobile">Mobile No:</label>
            <input type="text" id="mobile" placeholder="Mobile No" />
          </div>

          <div className="form-column">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" placeholder="Email address" />

            <label htmlFor="job-title">Job Title:</label>
            <input type="text" id="job-title" placeholder="Job Title" />
          </div>
        </div>

        <div className="skills">
          <div className="skills-column">
            <label>Please select all the skills you have:</label>

            <div className="checkbox-group">
              <input type="checkbox" id="php" />
              <label htmlFor="php">PHP Developer (CodeIgniter/Laravel)</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="nodejs" />
              <label htmlFor="nodejs">Node JS Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="python" />
              <label htmlFor="python">Python Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="react" />
              <label htmlFor="react">React Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="angular" />
              <label htmlFor="angular">Angular Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="html-css" />
              <label htmlFor="html-css">HTML/CSS Developer</label>
            </div>
          </div>

          <div className="skills-column">
            <div className="checkbox-group">
              <input type="checkbox" id="java" />
              <label htmlFor="java">Java Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="csharp" />
              <label htmlFor="csharp">C# Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="dotnet" />
              <label htmlFor="dotnet">.NET Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="android" />
              <label htmlFor="android">Android Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="ios" />
              <label htmlFor="ios">iOS Developer</label>
            </div>

            <div className="checkbox-group">
              <input type="checkbox" id="data-science" />
              <label htmlFor="data-science">Data Scientist</label>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <label htmlFor="experience">Job Experience:</label>
            <input type="text" id="experience" placeholder="Job Experience" />
          </div>

          <div className="form-column">
            <label htmlFor="current-ctc">Current CTC:</label>
            <input type="text" id="current-ctc" placeholder="Current CTC" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-column">
            <label htmlFor="expected-ctc">Expected CTC:</label>
            <input type="text" id="expected-ctc" placeholder="Expected CTC" />
          </div>

          <div className="form-column">
            <label htmlFor="cv">Upload Your CV:</label>
            <input type="file" id="cv" />
          </div>
        </div>

        <input type="submit" value="Submit" />
      </form>
    </div>
        {/* <section className='acs_fun_section'>
            {Fun.map((item,index) =>(
              <div className='content' key={index}>
                <h1>{item.heading}</h1>
                <p>{item.paragraph}</p>
              </div>
            ))}
        </section> */}
      </div>
      <Footer />
    </>
  );
};

export default JobApplicationForm;