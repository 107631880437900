import React from 'react';
import './Industries.css';
import industriesImage from '../Assest/industries.jpeg'; 
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Footer from '../Footer/Footer';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';

const Industries = () => {
  const navigate = useNavigate(); // Initialize the navigation hook

  return (
    <>
    <Navbar1/>
    <Navbar2/>
    <div className="industries-section">
      <div className="industries-text">
        <h1>Industries We Serve</h1>
        <p>
          We provide customized mobile and web app solutions for a vast array of
          industries worldwide. Our world-class IT services are designed to
          provide the best technology solutions that will promote your business
          to the next level. No matter what category your business falls under,
          we are able to develop and deliver a solution to meet your technology
          needs.
        </p>
        {/* <div className="industries-stats">
          <div className="stat">
            <div className="icon">📱</div>
            <h3>10+</h3>
            <p>Mobile Apps Developed</p>
          </div>
          <div className="stat">
            <div className="icon">👨‍💻</div>
            <h3>50+</h3>
            <p>High-Tech Professionals</p>
          </div>
          <div className="stat">
            <div className="icon">⏳</div>
            <h3>20+ Years</h3>
            <p>Industry Experience</p>
          </div>
        </div> */}
        <div className="industries-buttons">
          {/* <button className="estimate-btn" onClick={() => navigate('/get-estimate')}>Get An Estimate</button> */}
          <button className="contact-btn" onClick={() => navigate('/contact-us')}>Contact Us</button>
        </div>
      </div>
      <div className="industries-image">
        <img src={industriesImage} alt="Industries Illustration" />
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Industries;