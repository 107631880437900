import React from 'react';
import './BannerOutsorce.css'; // Separate CSS file
import img1 from '../Assest/banner-outsource.jpg.jpeg'
import BenefitSection from './BenifitSection';
import Expertise from './Experties';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';
import Footer from '../Footer/Footer';

const BannerOutsource = () => {
  return (
    <>
<Navbar1/>
<Navbar2/>
    <div className="banner">
      <div className="banner-content">
        <h1>Hire Dedicated Resource at Best Price</h1>
        <p>
          From startups to large enterprises, we deliver our customer's expectations
          meeting excellence of our app developers. Within a short span of 20+ years, ACS has emerged as one of the top Android app development companies in India, the USA, Denmark, and across the world.
        </p>

        {/* <div className="stats">
          <div className="stat-item">
            <div className="icon">📱</div>
            <h3>10+</h3>
            <p>Mobile Apps Developed</p>
          </div>
          <div className="stat-item">
            <div className="icon">👨‍💻</div>
            <h3>50+</h3>
            <p>High-Tech Professionals</p>
          </div>
          <div className="stat-item">
            <div className="icon">📅</div>
            <h3>20+ Years</h3>
            <p>Industry Experience</p>
          </div>
        </div> */}
        <div className="buttons">
          {/* <button className="estimate-btn">Get An Estimate</button> */}
          {/* <button className="contact-btn" onClick={() => navigate('/contact-us')}>Contact Us</button> */}

        </div>
      </div>
      <div className="banner-image">
        <img src={img1} alt="Development tools" />
      </div>
    </div>
    <BenefitSection/>
    <Expertise/>
    <Footer/>
    </>
  );
};

export default BannerOutsource;