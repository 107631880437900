import React from 'react';
import './Services.css'; // Add your styles here
import { useNavigate } from 'react-router-dom'; // Import useNavigate

// Import icons from a library like Font Awesome
import { FaShoppingCart, FaSuitcase, FaBuilding, FaMobileAlt, FaStore, FaFilm, FaSchool, FaPen, FaLaptopCode, FaPercent } from 'react-icons/fa';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';
import Footer from '../Footer/Footer';

const categories = [
  { icon: <FaShoppingCart />, label: 'E-Commerce', path: '/e-commerce' },
  { icon: <FaMobileAlt />, label: 'Mobile App', path: '/mobile-app' },
  { icon: <FaPen />, label: 'Logo and Banner', path: '/logo-banner' },
  { icon: <FaFilm />, label: 'Animation Video', path: '/animation-video' },
  { icon: <FaPercent />, label: 'Dynamic Website', path: '/dynamic-website' },
  { icon: <FaLaptopCode />, label: 'Static Website', path: '/static-website' },
  { icon: <FaPen />, label: 'Service Based Website', path: '/service-website' },
  { icon: <FaSchool />, label: 'School Website', path: '/school-website' },
];

const Categories = () => {
  const navigate = useNavigate(); // Use navigate from react-router-dom

  // Function to handle navigation when a card is clicked
  const handleCardClick = (path) => {
    navigate(path); // Navigate to the desired path
  };

  return (
    <>
    <Navbar1/>
    <Navbar2/>
    <div className="categories-section">
      <h2 className="categories-title">
        <span style={{ fontFamily: "monospace" }}>CATEGORIES</span>
        <br />
        <span className="product-categories">Product Categories</span>
      </h2>

      <div className="categories-grid">
        {categories.map((category, index) => (
          <div
            key={index}
            className="category-card"
            onClick={() => handleCardClick(category.path)} // Handle click for navigation
            style={{ cursor: 'pointer' }} // Add pointer cursor to indicate it's clickable
          >
            <div className="icon">{category.icon}</div>
            <h3 className="category-label">{category.label}</h3>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Categories;