import React from 'react'
import "./smmdigital.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import DigitalMarketing from "../../Component/Assest/pages/smm_img/Social_Media_Marketing.png"
import DigitalIcon1 from "../../Component/Assest/pages/smm_img/smm_icon-1.png"
import DigitalIcon2 from "../../Component/Assest/pages/smm_img/smm_icon-2.png"
import DigitalIcon3 from "../../Component/Assest/pages/smm_img/smm_icon-3.png"
import DigitalIcon4 from "../../Component/Assest/pages/smm_img/smm_icon-4.png"
import DigitalIcon5 from "../../Component/Assest/pages/smm_img/smm_icon-5.png"
import DigitalIcon6 from "../../Component/Assest/pages/smm_img/smm_icon-6.png"
import DigitalIcon7 from "../../Component/Assest/pages/smm_img/smm_icon-7.png"
import DigitalIcon8 from "../../Component/Assest/pages/smm_img/smm_icon-8.png"
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const SmmDigital = () => {

    const Digital = [
        {
            image:DigitalIcon1,
            delay: "500",
            title:"Enhanced Online Visibility ",
            desc:"Social media marketing is always evolving, turning into a potent tool for businesses. Platforms like Facebook, Twitter, LinkedIn, and Instagram can significantly boost your brand's visibility. By having a strong strategy for social media content, you can create more interactions like likes and shares, connecting with a wider online audience.",
        },
        {
            image:DigitalIcon2,
            delay: "600",
            title:"Improved Search Engine Rankings",
            desc:"Search engines, such as Google and Bing, now include social media updates and interactions in their search results, showing the value of social activity. When more people share your content on social media, your website gets more visitors, which can improve its position in search results. ",
        },
        {
            image:DigitalIcon3,
            delay: "700",
            title:"Precise Audience Focus",
            desc:"Content is effective only if it reaches the right people. With social media marketing, you can pinpoint and group your perfect customers based on age, location, and online habits. Social media experts study your specific market to grasp your target audience's online actions, then create content and ads tailored to their preferences. ",
        },
        {
            image:DigitalIcon4,
            delay: "500",
            title:"Enhanced Customer Outreach",
            desc:"Using social media marketing can help businesses reach more people. With platforms like Facebook or Instagram, companies can easily connect with customers, both old and new. By sharing updates and engaging content, they can spread the word about their products or services, ensuring more people learn about their brand and what they offer. This is called enhanced customer outreach. ",
        },
        {
            image:DigitalIcon5,
            delay: "600",
            title:"Total Brand Management ",
            desc:"Social media marketing plans are customized for your needs and budget. This lets you have full control over your brand's image, how much you spend, and your strategy. By understanding how to market on social media, you can present a consistent brand image that attracts your audience and keeps your marketing sharp. ",
        },
        {
            image:DigitalIcon6,
            delay: "700",
            title:"Strengthened Customer Confidence ",
            desc:"Using social media effectively helps you become a trusted brand and connect deeply with your audience. Our agency shares helpful content and talks about your company online to understand your customers better. We also use videos to show positive feedback and proof of your brand's quality. These methods are key to gaining your audience's trust and building a strong bond with them. ",
        },
        {
            image:DigitalIcon7,
            delay: "500",
            title:"Diverse Marketing Choices",
            desc:"In addition to platforms like Facebook, YouTube, LinkedIn, and Instagram, you have other options for social media marketing. These include WhatsApp, Messenger, WeChat, Tumblr, and Snapchat. Our social media marketing company conducts thorough assessments of your business, analyzes your audience, and checks what your competitors are doing to figure out the best social media platforms and strategies for your business. ",
        },
        {
            image:DigitalIcon1,
            delay: "600",
            title:"Greater Profit Potential ",
            desc:"Social media marketing, whether you're a small business or a big one, is a wallet-friendly way to connect with your perfect customers. You can display what you offer on social platforms without spending a lot. Plus, the best part is you can reach countless potential customers from different places without needing to work extra or spend more money. ",
        },
    ]

  return (
    <>
    <Navbar1/>
    <Navbar2/>
    <section className='smm_digital_section'>
        <div className='smm_digital_bg'></div>

        <div className='smm_digital_marketing'>
            <div className='text'>
                <h2>Social Media Marketing Basics</h2>
                <p>Showcase Your Company News and Story on the Right Platform</p>
            </div>
            <div className='smm_digital_marketing_flex'>
                <div className='image' data-aos="fade-right" data-aos-duration="4000">
                    <img src={DigitalMarketing} alt='DigitalMarketing'/>
                </div>
                <div className='content' data-aos="fade-left" data-aos-duration="4000">
                    <p>Social media platforms are not just for news and chat anymore. They play a big role in online search rankings and digital marketing too.</p>
                    <p>Did you know? There are 4.8 billion social media users worldwide, with 150 million new users added in between April 2022 and April 2023. On average, people spend over two hours daily using various social media and messaging apps.</p>
                </div>
            </div>
        </div>

        <div className='smm_advantage_section'>
        <div className='text'>
                <h2>What Are the Advantages of Using Social Media Marketing Services?</h2>
                <p>Social media marketing services bring many benefits to both new and established brands. When done right, they can boost website traffic, enhance SEO, foster stronger customer connections, and build brand loyalty. If you’re unsure about investing in social media marketing, here are the main advantages to help you decide. </p>
            </div>
            <div className='smm_advantage_flex'>
                {
                    Digital.map((item, index) =>(
                        <div className='smm_advantage_cards' key={index} data-aos="fade-up"
                        data-aos-duration="4000"
                        data-aos-delay={item.delay}>
                            <img src={item.image} alt=''/>
                            <h3>{item.title}</h3>
                            <p>{item.desc}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    </section>

    <OurClient/>
    <Footer/>
    </>
  )
}

export default SmmDigital