import React from 'react';
import './ProcessSection.css'; // Assuming you will add the styling here

const steps = [
  {
    number: '01',
    delay:'400',
    title: 'Discussion & Planning',
    description: 'The first step is ideation that leads to scope definition and preparing an action plan.',
    // link: '/discussion-planning' // Add the actual URL for the new page
  },
  {
    number: '02',
    delay:'500',
    title: 'Design & Documentation',
    description: 'Crucial step of laying out designs for aesthetic and visual appeal by project designers.',
    // link: '/design-html' // Add the actual URL for the new page
  },
  {
    number: '03',
    delay:'700',
    title: 'Development & Testing',
    description: 'Prototyping, coding, and developing followed by rigorous testing for quality control and assurance.',
    // link: '/development-testing' // Add the actual URL for the new page
  },
  {
    number: '04',
    delay:'700',
    title: 'Install & Deployment',
    description: 'Release, installation of solution, and deployment of updates and patches with continued performance monitoring.',
    // link: '/install-deployment' // Add the actual URL for the new page
  }
];

const ProcessSection = () => {
  const handleCardClick = (link) => {
    window.location.href = link;
  };

  return (
    <section className="process-section">
      <div className="intro-section" data-aos="fade-right"
     data-aos-offset="300"
     data-aos-easing="ease-in-sine">
        <h3 className="intro-heading">HOW WE WORK</h3>
        <h2 className="main-heading">How ACS Process assist your business</h2>
        <p className="intro-description">
          ACS brings on board an array of technical capacities with a team of talented and experienced professionals
          well-versed in end-to-end project solutions. Our approach is human-centric and customer-focused, employing
          agile project methodology.
        </p>
      
      </div>

      <div className="steps-section">
        {steps.map((step, index) => (
          <div className="step-card" key={index} data-aos="fade-up"
          data-aos-offset="300"
          data-aos-delay={step.delay}
          data-aos-easing="ease-in-sine">
            <div className="step-number">{step.number}</div>
            <h3 className="step-title">{step.title}</h3>
            <p className="step-description">{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProcessSection;