import React from 'react';
import './GlobalLearning2.css';

const servicesData = [
  { id: 1, name: 'Banking', icon: '💰',delay:"400", },
  { id: 2, name: 'Retail', icon: '🏷️',delay:"500", },
  { id: 3, name: 'Healthcare', icon: '💊',delay:"300", },
  { id: 4, name: 'Education', icon: '📚',delay:"400", },
  { id: 5, name: 'E-commerce', icon: '🛒',delay:"500", },
  { id: 6, name: 'Dating', icon: '💑',delay:"300", },
  { id: 7, name: 'Classified', icon: '👷',delay:"400", },
  { id: 8, name: 'News & Magazine', icon: '📰',delay:"500", },
  { id: 9, name: 'Job Portal', icon: '💼',delay:"300", },
  { id: 10, name: 'Maps Navigation', icon: '📍',delay:"400", },
  { id: 11, name: 'Food', icon: '🍽️',delay:"500", },
  { id: 12, name: 'Social Network', icon: '💬',delay:"300", },
  { id: 13, name: 'Real Estate', icon: '🏠',delay:"400", },
  { id: 14, name: 'Event App', icon: '🎫',delay:"500", },
  { id: 15, name: 'OTT Platform', icon: '📺',delay:"300", },

];

const GlobalLearning2 = () => {
  return (
    <div className="global-service-container">
      <h2>Industry We Serve</h2>
      <div className="global-service-grid">
        {servicesData.map((service) => (
          <div className="global-service-card" key={service.id} data-aos="fade-up"
          data-aos-delay={service.delay}>
            <div className="icon">{service.icon}</div>
            <h3>{service.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GlobalLearning2;
