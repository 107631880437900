import React from "react";
import "./wordpressdevelop.css";
import Navbar1 from "../../Component/Navbar/Navbar1";
import Navbar2 from "../../Component/Navbar/Navbar2";
import Wordpress from "../../Component/Assest/pages/wordpress_img/Transform-your-Website.webp";
import WordpressIcon from "../../Component/Assest/pages/wordpress_img/wordpress.png";
import WordpressIcon2 from "../../Component/Assest/pages/wordpress_img/wordpress.png";
import WordpressIcon3 from "../../Component/Assest/pages/wordpress_img/wordpress.png";
import WordpressIcon4 from "../../Component/Assest/pages/wordpress_img/wordpress.png";
import WordpressIcon5 from "../../Component/Assest/pages/wordpress_img/wordpress.png";
import WordpressIcon6 from "../../Component/Assest/pages/wordpress_img/wordpress.png";

import Language1 from "../../Component/Assest/pages/wordpress_img/language/php-img.png"
import Language2 from "../../Component/Assest/pages/wordpress_img/language/html.png"
import Language3 from "../../Component/Assest/pages/wordpress_img/language/CSS.png"
import Language4 from "../../Component/Assest/pages/wordpress_img/language/MySql.png"
import Language5 from "../../Component/Assest/pages/wordpress_img/language/Javascript.png"

import Tools1 from "../../Component/Assest/pages/wordpress_img/tools/sublime.webp"
import Tools2 from "../../Component/Assest/pages/wordpress_img/tools/PhpStorm-1.png"
import Tools3 from "../../Component/Assest/pages/wordpress_img/tools/mamp.webp"
import Tools4 from "../../Component/Assest/pages/wordpress_img/tools/xamp.webp"
import Tools5 from "../../Component/Assest/pages/wordpress_img/tools/git.png"

import Framwork1 from "../../Component/Assest/pages/wordpress_img/framwork/beans.png"
import Framwork2 from "../../Component/Assest/pages/wordpress_img/framwork/bootstrap.webp"
import Framwork3 from "../../Component/Assest/pages/wordpress_img/framwork/Elementor.png"
import Framwork4 from "../../Component/Assest/pages/wordpress_img/framwork/Divi.png"
import Framwork5 from "../../Component/Assest/pages/wordpress_img/framwork/genesis-framework.png"

import Plugins1 from "../../Component/Assest/pages/wordpress_img/plugins/Jetpack.png"
import Plugins2 from "../../Component/Assest/pages/wordpress_img/plugins/yoast.png"
import Plugins3 from "../../Component/Assest/pages/wordpress_img/plugins/woocommerce-1.avif"
import Plugins4 from "../../Component/Assest/pages/wordpress_img/plugins/wp-super-cache.png"
import Plugins5 from "../../Component/Assest/pages/wordpress_img/plugins/WPForms.png"

import Footer from "../../Component/Footer/Footer";
import OurClient from "../../Component/OurClient/OurClient";

const WordpressDevelop = () => {

  const ServicesCard = [
    {
      image: WordpressIcon,
      delay: "400",
      title: "WordPress Services",
      desc: "OrangeMantra offers all possible WordPress services, ranging from custom WordPress theme development, plugin customization, WordPress speed optimization, SEO, and integration with 3rd party modules and applications like Facebook Apps or for creating Facebook Apps for your WordPress website.",
    },
    {
      image: WordpressIcon2,
      delay: "400",
      title: "WordPress SEO ",
      desc: "OrangeMantra follows the best practices as per the latest strategy to advance your site for web indexing. We will work to bring the site to its higher ranking through . We search for relevant keywords, valuable content, backlinks for websites, and more.",
    },
    {
      image: WordpressIcon3,
      delay: "400",
      title: "WordPress Support",
      desc: "We offer quality WordPress support services. Our services include customization of website design, plugins, updates, migration, staging, maintenance, and troubleshooting. Our developers have years of experience in providing  and maintaining your website to match the pace of the updates.",
    },
    {
      image: WordpressIcon4,
      delay: "300",
      title: "WordPress Design",
      desc: "The design remains our foremost focus. If you are looking for a custom design, then you are at the right place. We, as a WordPress development company, offer a full service that includes a simple blog, a brochure, and a static website to an advanced ",
    },
    {
      image: WordpressIcon5,
      delay: "300",
      title: "WordPress Upgradation Service",
      desc: "A site without security is life without breath for us. So we understand its importance and we provide high user-end security levels on your site. From maintaining a site free from virus attacks. Hire us for all WordPress and plugin upgrades.",
    },
    {
      image: WordpressIcon6,
      delay: "300",
      title: "WordPress Customization",
      desc: "WordPress customization is done according to our client requirements on their websites. Our developers do it like a piece of art and seamlessly integrate third-party plugins and modules into your site suitably and perfectly to rank your site higher.",
    },
  ];


  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <section className="wordpress_section">
        <div className="wordpress_bg"></div>
        <div className="wordpress_business_groth">
          <div className="business_groth_flex">
            <div className="content" data-aos="fade-right">
              <h2>
                Transform your Website with our WordPress Development Services

              </h2>
              <p>
                If you wish to set up your special contributions to a blog
                webpage or are hoping to make a feature-rich and intuitive
                business site, then look no further than one of the best{" "}
                <a href="/wordpress">WordPress development</a> service
                providers: OrangeMantra. We are one of the most recognized
                WordPress development organizations around the globe and have
                been in this business for two decades. Our skilled group of
                WordPress developers has the intuition to deliver out-of-the-box
                web solutions utilizing state-of-the-art WordPress innovation.
              </p>
              <p>
                WordPress is one of the easiest and most powerful content
                management systems and comes with numerous features to make your
                experience pleasing and appealing. It maintains a worldwide
                community to help you 24*7. Numerous plugins are available to
                add features to your site.
              </p>
              <p>
                Orange Mantra offers WordPress development and solutions at
                cost-effective prices as well as high-end solutions. We strive
                hard to offer everyone with top-notch WordPress Website
                development services.
              </p>
            </div>
            <div className="image" data-aos="fade-left">
              <img src={Wordpress} alt="Wordpress_transport_image" />
            </div>
          </div>
        </div>

        <div className="wordpress_services">
          <div className="text">
            <h3>Diverse WordPress Services Offered By Us</h3>
            <p>
              Check out the range of services we provide to get your WordPress
              website development project done.
            </p>
          </div>
          <div className="wordpress_card_flex">
            {
              ServicesCard.map((services, index) => (
                <div className="wordpress_card" key={index} data-aos="fade-up"
                  data-aos-duration="4000"
                  data-aos-delay={services.delay}>
                  <div className="word_image">
                    <img src={services.image} />
                  </div>
                  <h4>{services.title}</h4>
                  <p>{services.desc}</p>
                </div>
              ))
            }
          </div>
        </div>

        <div className="wordpress_technical_section">
          <div className="text">
            <h1>Technical Stack used in our WordPress Development Company</h1>
            <p>
              As a forerunner in the field of WordPress Website development services, we rely upon the latest tools and technologies to provide you with top-notch solutions of your choice.</p>
          </div>
          <div className="technical_card_flex">
            <div className="technical_cards" data-aos="fade-up"
              data-aos-duration="4000">
              <h2>Languages</h2>
              <div className="img_flex">
                <img src={Language1} />
                <h5>PHP</h5>
              </div>
              <div className="img_flex">
                <img src={Language2} />
                <h5>HTML</h5>
              </div>
              <div className="img_flex">
                <img src={Language3} />
                <h5>CSS</h5>
              </div>
              <div className="img_flex">
                <img src={Language4} />
                <h5>MySQL</h5>
              </div>
              <div className="img_flex">
                <img src={Language5} />
                <h5>JavaScript</h5>
              </div>
            </div>
            <div className="technical_cards" data-aos="fade-up"
              data-aos-duration="5000">
              <h2>Tools</h2>
              <div className="img_flex">
                <img src={Tools1} />
                <h5>Sublime Text</h5>
              </div>
              <div className="img_flex">
                <img src={Tools2} />
                <h5>PhpStorm</h5>
              </div>
              <div className="img_flex">
                <img src={Tools3} />
                <h5>MAMP</h5>
              </div>
              <div className="img_flex">
                <img src={Tools4} />
                <h5>XAMPP</h5>
              </div>
              <div className="img_flex">
                <img src={Tools5} />
                <h5>Git</h5>
              </div>
            </div>
            <div className="technical_cards" data-aos="fade-up"
              data-aos-duration="6000">
              <h2>Framworks</h2>
              <div className="img_flex">
                <img src={Framwork1} />
                <h5>Beans</h5>
              </div>
              <div className="img_flex">
                <img src={Framwork2} />
                <h5>Bootstrap</h5>
              </div>
              <div className="img_flex">
                <img src={Framwork3} />
                <h5>Elementor</h5>
              </div>
              <div className="img_flex">
                <img src={Framwork4} />
                <h5>Divi</h5>
              </div>
              <div className="img_flex">
                <img src={Framwork5} />
                <h5>Genesis Framework</h5>
              </div>
            </div>
            <div className="technical_cards" data-aos="fade-up"
              data-aos-duration="7000">
              <h2>Plugins</h2>
              <div className="img_flex">
                <img src={Plugins1} />
                <h5>Jetpack</h5>
              </div>
              <div className="img_flex">
                <img src={Plugins2} />
                <h5>Yoast SEo</h5>
              </div>
              <div className="img_flex">
                <img src={Plugins3} />
                <h5>WooCommerce</h5>
              </div>
              <div className="img_flex">
                <img src={Plugins4} />
                <h5>WP Super Cache</h5>
              </div>
              <div className="img_flex">
                <img src={Plugins5} />
                <h5>WPForms</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurClient/>
      <Footer />
    </>
  );
};

export default WordpressDevelop;
