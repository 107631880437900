import React from 'react'
import "./crossplatfrom.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import CrossImg from "../../Component/Assest/pages/Cross-Platform.webp"
import Flutter from "../../Component/Assest/pages/flutter-lockup.png"
import ReactNative from "../../Component/Assest/pages/react-native-.png"
import Xmarin from "../../Component/Assest/pages/xamarin.png"
import Ionic from "../../Component/Assest/pages/ionic.png"
import Angular from "../../Component/Assest/pages/Angular.png"
import PhoneGap from "../../Component/Assest/pages/phonegap.webp"
import ChooseCross from "../../Component/Assest/pages/choose-cross.webp"

import ChooseIcon1 from "../../Component/Assest/pages/choose-icon-1.svg"
import ChooseIcon3 from "../../Component/Assest/pages/choose-icon-3.svg"
import ChooseIcon4 from "../../Component/Assest/pages/choose-icon-4.svg"
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const CrossPlatfrom = () => {
    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <div className='cross_platfrom'>
                <div className='cross_content_box'>
                    <h1>Team up with a cross-platform app development company to drive innovation</h1>
                    <p className='para'>Leverage the power of cross-platform application development to address new-age business opportunities.</p>
                    <div className='cross_flex'>
                        <div className='cross_content' data-aos="fade-right">
                            <p>We help businesses of all types, from startups and small companies to large enterprises, to get the most out of their apps with a cross-platform mobile app development services focus.</p>
                            <p>Team Rishabh makes use of technologies like HTML5, CSS3, and JavaScript to deliver mobile applications in line with business requirements. We have the know-how to utilize adaptable and powerful cross-platform mobile application development frameworks, such as <span>Flutter, React Native</span> & <span>Xamarin</span> for designing and developing native apps to offer the same rich user experience across all devices (including smartphones, tablets & desktops).</p>
                            <p>Our team spends time to deeply understand your requirement, and then apply agile development methodology to develop apps that address the needs of your end-users and business vertical. As deliver desired results with our <span>proven & flexible engagement models.</span> </p>
                            <p>So, whether you need full-fledged multi-platform application development support, or just need assistance with app modernization, we can provide the most relevant fit.</p>
                        </div>
                        <div className='image' data-aos="fade-left">
                            <img src={CrossImg} alt='CrossImg' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='cross_technologies'>
                <div className='cross_bg_image'></div>

                <div className='technologies'>
                    <h2>Tools & Technologies</h2>
                    <span></span>
                    <div className='tool_images'>
                        <img data-aos="fade-right" data-aos-delay="400" src={Flutter} alt='' title='Flutter' />
                        <img data-aos="fade-right" data-aos-delay="500" src={ReactNative} alt='' />
                        <img data-aos="fade-right" data-aos-delay="600" src={Xmarin} alt='' />
                        <img data-aos="fade-right" data-aos-delay="700" src={Ionic} alt='' />
                        <img data-aos="fade-right" data-aos-delay="800" src={Angular} alt='' />
                        <img data-aos="fade-right" data-aos-delay="900" src={PhoneGap} alt='' />
                    </div>
                </div>
            </div>

            <div className='choose_cross-platform'>
                <div className='choose_cross-platform_content'>
                    <div className='image_content'>
                        <div className='content' data-aos="fade-right">
                            <h3>Why choose us for cross-platform app development services?</h3>
                            <p className='choose_text'>Whatever your app idea, as a cross-platform app development company, our team possesses the platform knowledge and stack fluency to realize your vision. For more than a decade, Rishabh has remained at the forefront of mobile development, delivering solutions that meet even the most unique business needs.</p>
                        </div>
                        <div className='images' data-aos="fade-left">
                            <img src={ChooseCross} alt='' />
                        </div>
                    </div>

                    <div className='choose_icon_content'>
                        <div className='icon_content' data-aos="fade-up"
                            data-aos-duration="1000" data-aos-delay="500">
                            <div className='img_content'>
                                <img src={ChooseIcon1} alt='' />
                                <h4>Innovation-led & Result-oriented Approach</h4>
                            </div>
                            <p>We enable brands to realize strategic opportunities of increasing market reach with hybrid mobile app development while keeping the focus on end-user experience & conversions.</p>
                        </div>
                        <div className='icon_content' data-aos="fade-up"
                            data-aos-duration="1000" data-aos-delay="600">
                            <div className='img_content'>
                                <img src={ChooseIcon3} alt='' />
                                <h4>On-time & Reliable Delivery Methods</h4>
                            </div>
                            <p>With an agile methodology, we keep our clients involved at every stage. You can be confident of speedy delivery & cost-effective solutions, designed to achieve goals in the timeframe and budget.</p>
                        </div>

                        <div className='icon_content' data-aos="fade-up"
                            data-aos-duration="1000" data-aos-delay="700">
                            <div className='img_content'>
                                <img src={ChooseIcon3} alt='' />
                                <h4>Successful Track Record</h4>
                            </div>
                            <p>Rishabh Software features on the elite dashboard of Clutch and GoodFirms as the trusted partner for organizations globally. This recognition values our industry expertise, distinguished clientele, quality delivery & drive to go above and beyond the project requirements.</p>
                        </div>
                        <div className='icon_content' data-aos="fade-up"
                            data-aos-duration="1000" data-aos-delay="800">
                            <div className='img_content'>
                                <img src={ChooseIcon4} alt='' />
                                <h4>Experienced Cross-Platform Developers</h4>
                            </div>
                            <p>Our team of proficient developers keeps abreast of tech updates ensuring the best of custom cross-platform solution development. They also explore possibilities to create apps with an unbeatable UI/UX design.</p>
                        </div>
                    </div>
                </div>


            </div>

            <OurClient/>
            <Footer />
        </>
    )
}

export default CrossPlatfrom
