import './Navbar1.css';
import { Link } from 'react-router-dom';

const Navbar1 = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = '9315652636'; // Your WhatsApp number
    window.open(`https://wa.me/91${phoneNumber}`, '_blank');
  };

  return (
    <div className="nav-navbar1">
      <div className="nav-left-section">
        {/* Email and phone number for larger screens */}
        <span className="nav-email">
          <a href="mailto:info@acstechconsulting.com" className="nav-contact-link">
            <i className="fas fa-envelope"></i> info@acstechconsulting.com
          </a>
        </span>
        <div className="nav-phone">
          <a href="tel:+919315652636" className="nav-contact-link">
            <i className="fas fa-phone-alt"></i> +91 9315652636
          </a>
        </div>
        <div className="nav-phone">
          <a href="tel:+4591664592" className="nav-contact-link">
            <i className="fas fa-phone-alt"></i> +45 91 66 45 92
          </a>
        </div>
      </div>

      <div className="nav-right-section">
        {/* Social icons */}
        <div className="nav-social-icons">
          <span onClick={handleWhatsAppClick} className="nav-social-icon">
            <i className="fab fa-whatsapp"></i>
          </span>
          <Link to="https://www.facebook.com/profile.php?id=61563162418689&mibextid=ZbWKwL" className="nav-social-icon">
            <i className="fab fa-facebook-f"></i>
          </Link>
          <Link to="https://www.instagram.com/asvconsultingservices?igsh=MTQ0NjVoNGtpbGIwMg==" className="nav-social-icon">
            <i className="fab fa-instagram"></i>
          </Link>
          <Link to="https://www.linkedin.com/company/asv-consulting-services/" className="nav-social-icon">
            <i className="fab fa-linkedin-in"></i>
          </Link>

          {/* Display only phone icons and country codes for small screens */}
          <div className="nav-small-screen-icons">
            <a href="tel:+919315652636" className="nav-social-icon">
              <i className="fas fa-phone-alt"></i> +91
            </a>
            <a href="tel:+4591664592" className="nav-social-icon">
              <i className="fas fa-phone-alt"></i> +45
            </a>
            <a href="mailto:info@acstechconsulting.com" className="nav-social-icon">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar1;
