import React, { useState } from "react";
import "./Faq.css";
import icon4 from "../Assest/1tt.jpeg";

const faqs = [
  {
    delay:"400",
    question: "Where can we reach you?",
    answer:
      "#B06, H-169,Sector-63,Noida U.P - 201301",
  },
  {
    delay:"500",
    question: "How do we contact HR?",
    answer:
      "Please feel free to reach us at - hr@acstechconsulting.com",
  },
  {
    delay:"600",
    question: "How do we contact digital marketing team?",
    answer:
      "Please feel free to contact us - acs@acstechconsulting.com",
  },
  {
    delay:"700",
    question: "How can we schedule a meeting?",
    answer:
      "Please feel free to contact us- info@acstechconsulting.com ",
  },
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <div className="faq-left" data-aos="fade-right" data-aos-delay="500">
        <h4>FAQ'S</h4>
        <h1>
          General <span className="highlight-text2">Frequently</span> Asked
          Questions
        </h1>
        <p >
        ACS Tech Consulting is a leading IT services company that specializes in web development, mobile app development, SEO, staffing, and data solutions. We provide innovative and customized technology solutions to help businesses optimize their operations and drive growth.
        </p>
        <img src={icon4} alt="" className="faq-image" />
      </div>
      <div className="faq-right" >
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item" data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
          data-aos-delay={faq.delay}>
            <div className="faq-question" onClick={() => handleToggle(index)}>
              <div
                className="faq-question-icon"
                style={{ color: '#001f3f', fontSize: '20px' }} // Inline style for navy blue and size
              >
                ⓘ {/* Information icon */}
              </div>
              <p>{faq.question}</p>
              <div className="faq-toggle-icon">
                {activeIndex === index ? "▲" : "▼"}
              </div>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqSection;
