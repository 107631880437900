import React from 'react'
import "./salesforce.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import SalesForce from "../../Component/Assest/pages/salesforce_img/salesforce-bg.jpg"

import SalesCard1 from "../../Component/Assest/pages/salesforce_img/sales-card-1.webp"
import SalesCard2 from "../../Component/Assest/pages/salesforce_img/sales-card-2.webp"
import SalesCard3 from "../../Component/Assest/pages/salesforce_img/sales-card-3.webp"
import SalesCard4 from "../../Component/Assest/pages/salesforce_img/sales-card-4.webp"

import Salesgrow1 from "../../Component/Assest/pages/salesforce_img/sales_emp.webp"
import Salesgrow2 from "../../Component/Assest/pages/salesforce_img/sales_emp-2.webp"
import Salesgrow3 from "../../Component/Assest/pages/salesforce_img/sales_emp-3.webp"
import Salesgrow4 from "../../Component/Assest/pages/salesforce_img/sales_emp-4.webp"
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const Salesforce = () => {

    const SalesCard = [
        {
            image: SalesCard1,
            delay: "400",
            title: "Small Business",
            desc: "Sales, service, and email outreach tools in a single app.",
            anchor: "Learn More",
        },
        {
            image: SalesCard2,
            delay: "400",
            title: "Sales Cloud",
            desc: "Close more deals and speed up growth with the #1 CRM.",
            anchor: "Learn More",
        },
        {
            image: SalesCard3,
            delay: "400",
            title: "Service Cloud",
            desc: "Make customers happy faster and build loyalty with Service Cloud.",
            anchor: "Learn More",
        },
        {
            image: SalesCard4,
            delay: "400",
            title: "Marketing Cloud",
            desc: "Build customer relationships for life with data-first digital marketing.",
            anchor: "Learn More",
        },
    ]

    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section className='salesforce_section'>
                <div className='salesforce_flex'>
                    <div className='content' data-aos="fade-right">
                        <h1>Salesforce <br /> Asv Consulting Services</h1>
                        <p>Unite marketing, sales, and service in a single app. Try Salesforce Starter Suite today. There's nothing to install. No credit card required.</p>
                        <button className='get'>Start free trial</button>
                    </div>
                    <div className='image' data-aos="fade-left">
                        <img src={SalesForce} alt='NodeImg' />
                    </div>
                </div>
                <div className='salesforce_card_section'>
                    <div className='salesforce_card_flex'>
                        {
                            SalesCard.map((sales, index) => (
                                <div className='salesforce_cards' key={index} data-aos="fade-up"
                                    data-aos-duration="3000"
                                    data-aos-delay={sales.delay}>
                                    <img src={sales.image} />
                                    <div className='content'>
                                        <h3>{sales.title}</h3>
                                        <p>{sales.desc}</p>
                                        <a href={sales.anchor} >{sales.anchor}</a>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='salesforce_content_bg'>
                    <div className='salesforce_content' data-aos="fade-up"
                        data-aos-duration="4000">
                        <h1>We bring companies and customers together</h1>
                        <p>Salesforce is the world’s most trusted customer relationship management (CRM) platform. We help your marketing, sales, commerce, service and IT teams work as one from anywhere — so you can keep your customers happy everywhere.</p>
                        <div className='button_flex'>
                            <a href='#'>Start free trial</a>
                        </div>
                    </div>
                </div>

                <div className='sales_grow_business'>
                    <h1>How can we help grow your business?</h1>
                    <div className='sales_grow_flex'>
                        <div className='sales_cards' data-aos="fade-up"
                            data-aos-duration="4000">
                            <div className='sales_img'>
                                <img src={Salesgrow1} alt='' />
                            </div>
                            <a href='#'>Sell faster</a>
                        </div>
                        <div className='sales_cards' data-aos="fade-up"
                            data-aos-duration="4000">
                            <div className='sales_img'>
                                <img src={Salesgrow2} alt='' />
                            </div>
                            <a href='#'>Close more deals</a>
                        </div>
                        <div className='sales_cards' data-aos="fade-up"
                            data-aos-duration="4000">
                            <div className='sales_img'>
                                <img src={Salesgrow3} alt='' />
                            </div>
                            <a href='#'>Scale service</a>
                        </div>
                        <div className='sales_cards' data-aos="fade-up"
                            data-aos-duration="4000">
                            <div className='sales_img'>
                                <img src={Salesgrow4} alt='' />
                            </div>
                            <a href='#'>Build customer relationships</a>
                        </div>
                    </div>
                </div>
            </section>

            <OurClient/>
            <Footer />
        </>
    )
}

export default Salesforce
