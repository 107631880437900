import React from 'react'
import "./nodedevelopment.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import NodeImg from "../../Component/Assest/pages/node_img/node_img.webp"
import NodeImage from "../../Component/Assest/pages/node_img/nodejs-inner.webp"
import CircleIcon from "../../Component/Assest/pages/node_img/right-tick-blue.svg"
import RightArrow from "../../Component/Assest/pages/node_img/arrow-img-blue.svg"
import Fact1 from "../../Component/Assest/pages/node_img/fact1-1.svg"
import Fact2 from "../../Component/Assest/pages/node_img/fact2-1.svg"
import Fact3 from "../../Component/Assest/pages/node_img/fact3-1.svg"
import Fact4 from "../../Component/Assest/pages/node_img/fact4-1.svg"
import Fact5 from "../../Component/Assest/pages/node_img/fact7-1.svg"
import Fact6 from "../../Component/Assest/pages/node_img/fact8-1.svg"
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const Nodedevelopment = () => {

    const XcellCard = [
        {
            image: Fact1,
            delay:"400",
            title: "3",
            desc: "STATE-OF-THE-ART DEVELOPMENT FACILITIES",
        },
        {
            image: Fact2,
            delay:"400",
            title: "25000",
            desc: "SQ. MTR OF OFFICE SPACE",
        },
        {
            image: Fact1,
            delay:"400",
            title: "500+",
            desc: "SOFTWARE ENGINEERS & TECHNICAL STAFF",
        },
        {
            image: Fact4,
            delay:"300",
            title: "1500+",
            desc: "SOFTWARE DESIGNED, DEVELOPED & DEPLOYED",
        },
        {
            image: Fact5,
            delay:"300",
            title: "1000+",
            desc: "HAPPY CUSTOMERS",
        },
        {
            image: Fact6,
            delay:"300",
            title: "3",
            desc: "OWN SUCCESSFUL PRODUCTS",
        },
    ]

    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section className='node_development'>
                <div className='node_flex'>
                    <div className='content'>
                        <h1>NodeJS Development <br /> Asv Consulting Services</h1>
                        <p>Unparalleled Node JS development services tailored to your needs. A trusted Node JS development company delivering innovative solutions for seamless, scalable, and efficient applications.</p>
                        <button className='get'>Get a quote now!</button>
                    </div>
                    <div className='image'>
                        <img src={NodeImg} alt='NodeImg' />
                    </div>
                </div>
                <div className='node_information'>
                    <div className='node_img' data-aos="fade-right">
                        <img src={NodeImage} alt='NodeImage' />
                    </div>
                    <div className='node_content' data-aos="fade-left">
                        <h2>WHY NODEJS</h2>
                        <p>Why should you choose NodeJS?</p>
                        <div className='node_text_flex'>
                            <div className='text_icon'>
                                <div className='text_icon_flex'>
                                    <img src={CircleIcon} alt='' />
                                    <h5>Scalability</h5>
                                </div>
                                <div className='text_icon_flex'>
                                    <img src={CircleIcon} alt='' />
                                    <h5>Easy coding</h5>
                                </div>
                                <div className='text_icon_flex'>
                                    <img src={CircleIcon} alt='' />
                                    <h5>Code reusability</h5>
                                </div>
                            </div>
                            <div className='text_icon'>
                                <div className='text_icon_flex'>
                                    <img src={CircleIcon} alt='' />
                                    <h5>Real-time web apps</h5>
                                </div>
                                <div className='text_icon_flex'>
                                    <img src={CircleIcon} alt='' />
                                    <h5>Dynamic NPM</h5>
                                </div>
                                <div className='text_icon_flex'>
                                    <img src={CircleIcon} alt='' />
                                    <h5>SEO friendly apps</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='related_services'>
                    <div className='realted_text'>
                        <h1>RELATED SERVICES</h1>
                        <p>We Have the Perfect Services for your Project</p>
                    </div>
                    <div className='realted_cards'>
                        <div className='card' data-aos="fade-up"
                            data-aos-duration="4000">
                            <h3>Custom software development</h3>
                            <img src={RightArrow} alt='' />
                        </div>
                        <div className='card' data-aos="fade-up"
                            data-aos-duration="4000">
                            <h3>Mobile app development</h3>
                            <img src={RightArrow} alt='' />
                        </div>
                        <div className='card' data-aos="fade-up"
                            data-aos-duration="4000">
                            <h3>UI/UX design</h3>
                            <img src={RightArrow} alt='' />
                        </div>

                        <div className='card' data-aos="fade-up"
                            data-aos-duration="3000">
                            <h3>Web development</h3>
                            <img src={RightArrow} alt='' />
                        </div>
                        <div className='card' data-aos="fade-up"
                            data-aos-duration="3000">
                            <h3>Product engineering
                            </h3>
                            <img src={RightArrow} alt='' />
                        </div>
                        <div className='card' data-aos="fade-up"
                            data-aos-duration="3000">
                            <h3> QA & testing</h3>
                            <img src={RightArrow} alt='' />
                        </div>
                    </div>
                </div>

                <div className='openxcell_section'>
                    <div className='openxcell_text'>
                        <h1>WHY OPENXCELL?</h1>
                        <p>Why Choose Us As your Node JS Web Development Company</p>
                    </div>
                    <div className='openxcell_card'>
                        {
                            XcellCard.map((Xcell, index) => (
                                <div className='xcell_card' key={index} data-aos="fade-up"
                                data-aos-duration="4000"
                                data-aos-delay={Xcell.delay}>
                                    <img src={Xcell.image} />
                                    <h2>{Xcell.title}</h2>
                                    <p>{Xcell.desc}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <OurClient/>
            <Footer />
        </>
    )
}

export default Nodedevelopment
