import React, { useState, useEffect } from 'react';
import './StaticsBanner.css';

const StatisticsBanner = () => {
  // States for each number with initial value set to zero
  const [years, setYears] = useState(0);
  const [products, setProducts] = useState(0);
  const [teamMembers, setTeamMembers] = useState(0);
  const [clients, setClients] = useState(0);

  // Function to animate values from 0 to the target value
  const animateValue = (start, end, setter, duration = 1500) => {
    const stepTime = Math.abs(Math.floor(duration / (end - start)));
    const timer = setInterval(() => {
      start += 1;
      setter(start);
      if (start === end) {
        clearInterval(timer);
      }
    }, stepTime);
  };

  // UseEffect to trigger the animation when component mounts
  useEffect(() => {
    animateValue(0, 5, setYears);
    animateValue(0, 100, setProducts);
    animateValue(0, 50, setTeamMembers);
    animateValue(0, 350, setClients);
  }, []);

  return (
    <div className="statistics-banner">
      <div className="statistic">
        <h1>{years}{years >= 5 ? '+' : ''}</h1>
        <p>Years Experience</p>
      </div>
      <div className="statistic">
        <h1>{products}{products >= 100 ? '+' : ''}</h1>
        <p>Services</p>
      </div>
      <div className="statistic">
        <h1>{teamMembers}{teamMembers >= 50 ? '+' : ''}</h1>
        <p>Team Members</p>
      </div>
      <div className="statistic">
        <h1>{clients}{clients >= 250 ? '+' : ''}</h1>
        <p>Clients</p>
      </div>
    </div>
  );
};

export default StatisticsBanner;