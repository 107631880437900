import React from 'react';
import './Term&Condition.css'; // Create a separate CSS file for styles
import Footer from '../Footer/Footer';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';

const TermsConditions = () => {
  return (
    <>
    <Navbar1/>
    <Navbar2/>
    <div className="terms-conditions-container">
      <h1>Terms and Conditions</h1>
      <p><strong>Effective Date:</strong></p>

      <section>
        <h2>1. Services</h2>
        <p>
          ASV Consulting Services (ACS) provides IT development and consulting services, including software development,
          web development, and other technology solutions. The scope of services will be defined in individual service
          agreements or proposals.
        </p>
      </section>

      <section>
        <h2>2. Intellectual Property</h2>
        <p>
          All content, designs, graphics, and other materials created by ACS during the delivery of services remain the
          intellectual property of ACS unless otherwise agreed in writing.
        </p>
        <ul>
          <li>
            <strong>Client Data:</strong> Any data provided by the client remains their intellectual property. ACS will
            not use, share, or distribute client data without written consent.
          </li>
          <li>
            <strong>Licensing:</strong> ACS grants you a non-exclusive, non-transferable license to use any software or
            systems developed under these Terms.
          </li>
        </ul>
      </section>

      <section>
        <h2>3. Payments</h2>
        <p>
          Clients agree to pay for services as outlined in individual agreements or proposals.
        </p>
        <ul>
          <li><strong>Billing:</strong> Payment terms and schedules will be defined in the respective service agreements.</li>
          <li><strong>Late Payments:</strong> A late fee may apply if payment is not made within the agreed timeframe.</li>
        </ul>
      </section>

      <section>
        <h2>4. Confidentiality</h2>
        <p>
          Both ACS and the client agree to maintain the confidentiality of all proprietary and sensitive information
          shared during the project, including technical details, business information, and client data.
        </p>
      </section>

      <section>
        <h2>5. Limitations of Liability</h2>
        <ul>
          <li>
            <strong>Warranties:</strong> ACS provides services on an "as is" basis, with no warranties, express or implied.
          </li>
          <li>
            <strong>Liability Cap:</strong> The total liability of ACS will not exceed the amount paid for services rendered.
          </li>
        </ul>
      </section>

      <section>
        <h2>6. Termination</h2>
        <p>
          Either party may terminate the service agreement with 30 days' notice, unless otherwise specified. In the event
          of termination, all outstanding payments must be settled, and all services provided will cease.
        </p>
      </section>

      <section>
        <h2>7. Governing Law</h2>
        <p>
          These Terms are governed by the laws of [Insert Jurisdiction]. Any disputes arising under these Terms shall be
          resolved in the courts of [Insert Jurisdiction].
        </p>
      </section>
    </div>
    <Footer/>
    </>
  );
};

export default TermsConditions;
