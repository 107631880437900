import React from 'react'
import "./cdframwork.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import CloudIcon1 from "../../Component/Assest/pages/cd_img/Icons-1.webp"
import CloudIcon2 from "../../Component/Assest/pages/cd_img/Icons-2.webp"
import CloudIcon3 from "../../Component/Assest/pages/cd_img/Icons-3.webp"
import CloudIcon4 from "../../Component/Assest/pages/cd_img/Icons-4.webp"
import CloudIcon5 from "../../Component/Assest/pages/cd_img/Icons-5.webp"
import CloudIcon6 from "../../Component/Assest/pages/cd_img/Icons-6.webp"
import CircleIcon from "../../Component/Assest/pages/cd_img/circle-icon.svg"

import Quote from "../../Component/Assest/pages/cd_img/quote.webp"
import Iith from "../../Component/Assest/pages/cd_img/iith.webp"
import Inetum from "../../Component/Assest/pages/cd_img/inetum-01.webp"
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const CdFramwork = () => {

  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <section className='cd_framwork_section'>
        <div className='cd_framwork_bg'></div>

        <div className='cd_framwork_content'>
          <h2>Everything You Need to Build Apps in the Cloud</h2>
          <div className='cd_framwork_flex'>
            <div className='cd_framwork_cards' data-aos="fade-up"
              data-aos-duration="5000">
              <div className='top'>
                <img src={CloudIcon1} alt='' />
                <h3>Hosting</h3>
              </div>
              <div className='bottom'>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Host SaaS products</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Host web apps</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Host APIs</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Host jobs</p>
                </div>
              </div>
            </div>

            <div className='cd_framwork_cards' data-aos="fade-up"
              data-aos-duration="6000">
              <div className='top'>
                <img src={CloudIcon2} alt='' />
                <h3>Development</h3>
              </div>
              <div className='bottom'>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Multi-version development</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>VSCode plugin</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>GitHub, GitLab and BitBucket integration</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Unit and integration testing</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>AI augmented engineering</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Cell-based architecture</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>API marketplace and reuse</p>
                </div>
              </div>
            </div>

            <div className='cd_framwork_cards' data-aos="fade-up"
              data-aos-duration="7000">
              <div className='top'>
                <img src={CloudIcon3} alt='' />
                <h3>Observability</h3>
              </div>
              <div className='bottom'>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Troubleshooting and monitoring</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>DORA metrics</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Delivery insights</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Usage insights</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>eBPF and Cilium-based observability</p>
                </div>
              </div>
            </div>

            <div className='cd_framwork_cards' data-aos="fade-up"
              data-aos-duration="6000">
              <div className='top'>
                <img src={CloudIcon4} alt='' />
                <h3>Deployment</h3>
              </div>
              <div className='bottom'>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Multi-environment deployment</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Multi-cloud deployment</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Zero trust deployment</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Scalable deployment</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Kubernetes deployment</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Highly available deployment</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>CICD</p>
                </div>
              </div>
            </div>

            <div className='cd_framwork_cards' data-aos="fade-up"
              data-aos-duration="5000">
              <div className='top'>
                <img src={CloudIcon5} alt='' />
                <h3>Security</h3>
              </div>
              <div className='bottom'>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>User authentication</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Authorization management</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Secure configuration management</p>
                </div>
              </div>
            </div>

            <div className='cd_framwork_cards' data-aos="fade-up"
              data-aos-duration="4000">
              <div className='top'>
                <img src={CloudIcon6} alt='' />
                <h3>Management</h3>
              </div>
              <div className='bottom'>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Manage multiple projects and components</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Team management</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>API management and portal</p>
                </div>
                <div className='flex'>
                  <img src={CircleIcon} alt='' />
                  <p>Cost optimization</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='cd_count_section'>
          <div className='count' data-aos="fade-up"
            data-aos-duration="4000">
            <h1>20K+</h1>
            <p>Users</p>
          </div>
          <div className='count' data-aos="fade-up"
            data-aos-duration="4000">
            <h1>24K+</h1>
            <p>Components</p>
          </div>
          <div className='count' data-aos="fade-up"
            data-aos-duration="4000">
            <h1>460K+</h1>
            <p>Deployments</p>
          </div>
          <div className='count' data-aos="fade-up"
            data-aos-duration="4000">
            <h1>1.8B+</h1>
            <p>Transactions</p>
          </div>
        </div>

        <div className='cd_content_section'>
          <div className='content' data-aos="fade-up"
            data-aos-duration="4000">
            <img className='quote' src={Quote} alt='Quote' />
            <div className='text'>
              <p>IIIT Hyderabad works on various innovative technology solutions that help nonprofits in healthcare, education and other sectors to support the communities they serve. Using Choreo and its marketplace, we are providing a one-stop-shop where nonprofits can access APIs to applications and services without having to know which organizations they come from. Choreo also automates API documentation, single sign-on, and management of the underlying infrastructure, which has freed up our team to focus on making new solutions available faster.</p>
            </div>
            <div className='image_flex'>
              <div>
                <span>Khoushik Ananth</span>
                <br />
                <span>Lead Engineer </span>
              </div>
              <div className='img'>
                <img src={Iith} alt='Iith' />
              </div>
            </div>
          </div>
          <div className='content' data-aos="fade-up"
            data-aos-duration="4000">
            <img className='quote' src={Quote} alt='Quote' />
            <div className='text'>
              <p>Choreo allows you to develop APIs with a great user experience that's focused on the developer like never before. You are fully productive from start to finish. Choreo has knocked down the time-to-market!</p>
            </div>
            <div className='image_flex'>
              <div>
                <span>Fidel Prieto Estrada</span>
                <br />
                <span>Integration Consultant</span>
              </div>
              <div className='img'>
                <img src={Inetum} alt='Inetum' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurClient />

      <Footer />
    </>
  )
}

export default CdFramwork
