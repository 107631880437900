import React from 'react'
import "./blog-sec.css"
import Navbar1 from '../../Navbar/Navbar1'
import Navbar2 from '../../Navbar/Navbar2'
import OurClient from '../../OurClient/OurClient'
import Footer from '../../Footer/Footer'
import { IoMdHome } from "react-icons/io";
import Blog1 from "../../Assest/pages/reactnative_img/native-vs-fluter.jpg"

const BlogSec5 = () => {

  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=9315652636', '_blank');
  };

  const BlogSection = [
    {
      image: Blog1,
      heading: "React Native vs Flutter - Choose your Hero React native, Flutter",
      title: "Also, knowledge of key metrics is necessary to create a well-structured marketing strategy. Evaluating",
      para: "Flutter draws attention to Dart, an object-oriented language from Google that, according to many domain specialists, is the future of all mobile, web, and desktop development. Compared with JavaScript (used by its main competitor, React Native), Dart is fully object-oriented and strongly typed. What does this mean for your business? The code delivered in Dart is much more resistant to errors and much easier to maintain, even for many years in a changing team. Undoubtedly, the Flutter programming language is one of its greatest advantages. ",
      para: "Build native apps for iOS and Android devices using React Native to optimize your mobile development processes. Unlike many hybrid apps, we can help you achieve an exceptional user interface (UI) and feature-rich functionality We offer integration and migration services, with hands-on experience in both. Our team works on a wide variety of applications using React Native. We also migrate apps from other platforms without losing any data.",
      para: "At Softweb, we employ the entire React Native library to provide the most engaging and creative user experience for your business apps. We are driven to deliver real-time experiences across several devices to ensure UI/UX gaps are filled After every release of a product, the necessity for care and control is crucial. We provide post-launch support and maintenance to ensure that all bugs are resolved, and your product is functioning as expected.",
      home: "Home",
      anchor1: "/",
      anchor2: "https://acstechconsulting.com/",
      h1: "1. Automated Communication",
      h2: "2. Predictive Analysis for Admission",
      h3: "3. Enhanced Personalization",
    }
  ]

  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className='blog_sectionPage'>
        <div className='blog_bg'></div>
        <div className='blog_container'>
          <div className='blog_section_flexes'>
            {
              BlogSection.map((item, index) => (
                <div className='blog_section_boxes' key={index}>
                  <div className='img' data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <img src={item.image} />
                  </div>
                  <div className='content' data-aos="fade-left"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <h2>{item.heading}</h2>
                    <p>{item.title}</p>
                    <div className='links'>
                      <a href={item.anchor1}><IoMdHome /> {item.home}  </a>
                    </div>
                    <div className="footer-acssocial">
                      <span onClick={handleWhatsAppClick} className="social-icon">
                        <i className="fab fa-whatsapp"></i>
                      </span>
                      <a href="https://www.facebook.com/profile.php?id=61563162418689&mibextid=ZbWKwL" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="https://www.instagram.com/asvconsultingservices?igsh=MTQ0NjVoNGtpbGIwMg==" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/asv-consulting-services/" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>

          {
            BlogSection.map((text, index) => (
              <div className='text' key={index}>
                <div data-aos="fade-up"
                  data-aos-duration="2000">
                  <h3>{text.h1}</h3>
                  <p>{text.para}</p>
                </div>
                <div data-aos="fade-up"
                  data-aos-duration="2000">
                  <h3>{text.h2}</h3>
                  <p>{text.para}</p>
                </div>
                <div data-aos="fade-up"
                  data-aos-duration="2000">
                  <h3>{text.h3}</h3>
                  <p>{text.para}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <OurClient />
      <Footer />
    </>
  )
}

export default BlogSec5
