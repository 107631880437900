import React, { useState } from 'react';
import './ContactUs.css';
import Footer from '../Footer/Footer';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';
import contactImage from '../Assest/cacs.jpg';

const ContactUs = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '', // Clear errors for the specific field
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://api.eduuxpert.com/api/contacts/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        alert(result.message);
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
        setErrors({});
      } else if (response.status === 422) {
        const errorResponse = await response.json();
        setErrors(errorResponse.errors || {});
      } else {
        const error = await response.json();
        alert("Error: " + error.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className="contact-us-section">
        <div className="contact-content">
          <div className="contact-image-container">
            <img src={contactImage} alt="Contact" className="contact-image" />
          </div>
          <div className="message-form">
            <h3>Send us a message <span role="img" aria-label="rocket">🚀</span></h3>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Full name*"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? 'error' : ''}
              />
              {errors.name && <small className="error_message">{errors.name}</small>}

              <input
                type="email"
                name="email"
                placeholder="Email address*"
                value={formData.email}
                onChange={handleChange}
                className={errors.email ? 'error' : ''}
              />
              {errors.email && <small className="error_message">{errors.email}</small>}

              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                className={errors.subject ? 'error' : ''}
              />
              {errors.subject && <small className="error_message">{errors.subject}</small>}

             <div>
             <textarea
                name="message"
                placeholder="Tell us more about your project*"
                value={formData.message}
                onChange={handleChange}
                className={errors.message ? 'error' : ''}
              ></textarea>
              {errors.message && <small className="error_message">{errors.message}</small>}
             </div>

              <button type="submit" className="contactus-btn">
                Submit
              </button>
            </form>
          </div>
        </div>

        {/* Address and Map Section in One Row */}
        <div className="address-map-content">
          <div className="address-info">
            <h3>Contact Us</h3>
            <p># B06, H-169, Sector 63, Noida, 201301, U.P., INDIA</p>
            <p>Email: <a href="mailto:info@acstechconsulting.com">info@acstechconsulting.com</a></p>
            {/* Phone Numbers Section */}
            <div className="country-numbers">
              <h3>Call Us</h3>
              <ul className='call-number'>
                <li>
                  India: <a href="tel:+919315652636">+91 9315652636</a> |
                  <a href="https://wa.me/919315652636" target="_blank" rel="noopener noreferrer"> WhatsApp</a>
                </li>
                <li>
                  USA: <a href="tel:+12024465842">+1 (945) 301-9521</a> |
                  <a href="https://wa.me/19453019521" target="_blank" rel="noopener noreferrer"> WhatsApp</a>
                </li>
                <li>
                  Denmark: <a href="tel:+4591664592">+45 91 66 45 92</a> |
                  <a href="https://wa.me/4591664592" target="_blank" rel="noopener noreferrer"> WhatsApp</a>
                </li>
              </ul>
            </div>
          </div>
          {/* Map Section */}
          <div className="map-container">
            <h3>Our Location</h3>
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13701.748012430363!2d77.35908080501615!3d28.58024301452485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c4e4e4e4e4e4f%3A0xe4e4e4e4e4e4e4e4!2sB06%2C%20H-169%2C%20Sector%2063%2C%20Noida%2C%20U.P.%20201301!5e0!3m2!1sen!2sin!4v1600000000000!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactUs;
