import React from 'react'
import "./contentwriting.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import Artbord1 from "../../Component/Assest/pages/con_write_img/Artboard-1.svg"
import Artbord2 from "../../Component/Assest/pages/con_write_img/Artboard-2.svg"
import Artbord3 from "../../Component/Assest/pages/con_write_img/Artboard-3.svg"
import Artbord4 from "../../Component/Assest/pages/con_write_img/Artboard-4.svg"

import TrustIcon1 from "../../Component/Assest/pages/con_write_img/trust-icon-1.svg"
import TrustIcon2 from "../../Component/Assest/pages/con_write_img/trust-icon-2.svg"
import TrustIcon3 from "../../Component/Assest/pages/con_write_img/trust-icon-3.svg"
import TrustIcon4 from "../../Component/Assest/pages/con_write_img/trust-icon-4.svg"
import TrustIcon5 from "../../Component/Assest/pages/con_write_img/trust-icon-5.svg"
import TrustIcon6 from "../../Component/Assest/pages/con_write_img/trust-icon-6.svg"
import TrustIcon7 from "../../Component/Assest/pages/con_write_img/trust-icon-7.svg"
import TrustIcon8 from "../../Component/Assest/pages/con_write_img/trust-icon-8.svg"
import TrustIcon9 from "../../Component/Assest/pages/con_write_img/trust-icon-9.svg"
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const ContentWriting = () => {

    const WorkContent = [
        {
            image: Artbord1,
            delay: "600",
            title: "Agencies",
            desc: "We partner with agencies to support the content and copywriting needs of their clients.",
        },
        {
            image: Artbord2,
            delay: "700",
            title: "Publishers",
            desc: "We support digital publishers by writing targeted content for their different sites at scale.",
        },
        {
            image: Artbord3,
            delay: "800",
            title: "eCommerce",
            desc: "We write product descriptions, web pages, and category pages with an emphasis on SEO.",
        },
        {
            image: Artbord4,
            delay: "900",
            title: "Brands",
            desc: "We develop your voice and tone and write custom content for brands big and small.",
        },
    ]

    const TrustContent = [
        {
            Icon: TrustIcon1,
            delay: "600",
            Heading: "Vetted, hand-selected, professional writers",
        },
        {
            Icon: TrustIcon2,
            delay: "600",
            Heading: "Editing and proofreading",
        },
        {
            Icon: TrustIcon3,
            delay: "600",
            Heading: "Account management and custom calendars",
        },
        {
            Icon: TrustIcon4,
            delay: "700",
            Heading: "Streamlined, fully scalable platform",
        },
        {
            Icon: TrustIcon5,
            delay: "700",
            Heading: "Topic pitching and ideation",
        },
        {
            Icon: TrustIcon6,
            delay: "700",
            Heading: "Specific industry expertise",
        },
        {
            Icon: TrustIcon7,
            delay: "800",
            Heading: "Revisions are always included",
        },
        {
            Icon: TrustIcon8,
            delay: "800",
            Heading: "100% original, custom content",
        },
        {
            Icon: TrustIcon9,
            delay: "800",
            Heading: "100% content satisfaction",
        },
    ]

    const FAQ = [
        {
            delay: "500",
            Heading1: "What is eCommerce content writing?",
            Paragraph1: "eCommerce content writing applies to product description copy, category page copy, articles, blogs, FAQ copy, and other written content on an eCommerce website. Brands create this type of content in order to build out their brand voice, connect with and inform buyers on their buying journey, write about relevant keywords and ultimately drive sales.",
        },
        {
            delay: "600",
            Heading2: "How do I become an eCommerce content writer?",
            Paragraph2: "There are many ways to become an eCommerce content writer or eCommerce copywriter. You can work directly with a brand or you can work with a company like ContentWriters that has many clients in the eCommerce space.",
            Paragraph3: "If you are interested in working with ContentWriters, sign up here.",
        },
        {
            delay: "700",
            Heading3: "Why is content writing important in eCommerce?",
            Paragraph4: "Content writing is crucial to the success of your eCommerce brand. Having original, unique content on your website not only allows a brand to differentiate itself from competitors – it also improves SEO performance and builds a brand voice that customers know and trust.",
            Paragraph5: "Search engines are constantly evolving, so it is important to stay up to date with the latest changes and stay agile as a content marketing team. Not only that, but content such as blogs or local pages can go out of date. Keeping your content up to date will help maintain trust between your brand and its audience.",
        },
        {
            delay: "800",
            Heading4: "How can I engage with ContentWriters for eCommerce content writing services?",
            Paragraph6: "If you are looking to scale your eCommerce SEO and need category page content, product description writing services, FAQ content or local pages, or a high volume of blog/article content (i.e. more than 10 articles per month) we will work with your team under our Managed Services offering.",
            Paragraph7: "Your Content Success Specialist builds your team of writers, creates a content calendar, manages deadlines, and ensures quality and consistency with your content over the duration of our relationship. Leveraging our proprietary content management platform, we will build a workflow to manage your assignments at scale.",
            Paragraph8: "For some content types, ordering through our platform via Self-Service is an option. For example, if you need eCommerce blogs or articles or eCommerce email marketing support, you can order with self-service.",
            Paragraph9: "Create an account here to get started with self-service, or book a meeting to discuss managed services here.",
        },
        {
            delay: "900",
            Heading5: "Does ContentWriters provide keyword research for eCommerce clients?",
            Paragraph10: "When we provide eCommerce SEO services, we often work alongside marketing teams who handle SEO keyword research in-house. Companies already working with SEO agencies will have those agencies provide us with the eCommerce marketing strategy.",
            Paragraph11: "For companies that don't conduct SEO keyword research in-house or with a partner, we provide customized keyword-first and topic-first approaches that compliment our content writing services. Our team can research specific keywords and pitch topics for articles for you, or we can take topics from you and then research relevant keywords. Reach out to our sales team here to get a quote for keyword research services.",
        },
    ]

    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section className='content_writing_section'>
                <div className='content_writing_bg'></div>

                <div className='content_writing_content'>
                    <div className='text'>
                        <h2>Content that works</h2>
                        <p>Whatever industry you’re in, you need expert writers who can engage your audience. We’ll find those writers for you.</p>
                    </div>
                    <div className='content_writing_flex'>
                        {
                            WorkContent.map((item, index) => (
                                <div className='content_writing_cards' key={index} data-aos="fade-up"
                                    data-aos-duration="4000"
                                    data-aos-delay={item.delay}>
                                    <img src={item.image} alt='' />
                                    <h3>{item.title}</h3>
                                    <p>{item.desc}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='content_writing_trust'>
                    <h2>Content You Can Trust</h2>
                    <div className='content_writing_trust_flex'>
                        {
                            TrustContent.map((Icons, index) => (
                                <div className='trust_box' key={index} data-aos="fade-up"
                                    data-aos-duration="4000"
                                    data-aos-delay={Icons.delay}>
                                    <img src={Icons.Icon} alt='trust-icon' />
                                    <h5>{Icons.Heading}</h5>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className='faq_content_section'>
                    <h2>FAQ</h2>
                    <div className='faq_content'>
                        {
                            FAQ.map((faq, index) => (
                                <div className='text' key={index} data-aos="fade-up"
                                    data-aos-duration="4000"
                                    data-aos-delay={faq.delay}>
                                    <h3>{faq.Heading1}</h3>
                                    <p>{faq.Paragraph1}</p>
                                    <h3>{faq.Heading2}</h3>
                                    <p>{faq.Paragraph2}</p>
                                    <p>{faq.Paragraph3}</p>
                                    <h3>{faq.Heading3}</h3>
                                    <p>{faq.Paragraph4}</p>
                                    <p>{faq.Paragraph5}</p>
                                    <h3>{faq.Heading4}</h3>
                                    <p>{faq.Paragraph6}</p>
                                    <p>{faq.Paragraph7}</p>
                                    <p>{faq.Paragraph8}</p>
                                    <p>{faq.Paragraph9}</p>
                                    <h3>{faq.Heading5}</h3>
                                    <p>{faq.Paragraph10}</p>
                                    <p>{faq.Paragraph11}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <OurClient />

            <Footer />
        </>
    )
}

export default ContentWriting