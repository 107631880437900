import React from 'react';
import './PrivacyPolicy.css'; // Create a separate CSS file for styles
import Footer from '../Footer/Footer';
import Navbar1 from '../Navbar/Navbar1';
import Navbar2 from '../Navbar/Navbar2';

const PrivacyPolicy = () => {
  return (
    <>
    <Navbar1/>
    <Navbar2/>
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> </p>

      <section>
        <h2>1. Information We Collect</h2>
        <p>
          We may collect the following types of information when you use our services or interact with our website:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> Name, email address, phone number, business information, etc., provided voluntarily.</li>
          <li><strong>Technical Information:</strong> IP address, browser type, operating system, and other analytics data collected automatically through cookies or similar technologies.</li>
        </ul>
      </section>

      <section>
        <h2>2. How We Use Your Information</h2>
        <ul>
          <li>Provide and improve our services.</li>
          <li>Respond to your inquiries or provide customer support.</li>
          <li>Send promotional materials or newsletters (if consent is provided).</li>
          <li>Analyze website traffic and user behavior for improvement.</li>
        </ul>
      </section>

      <section>
        <h2>3. Information Sharing</h2>
        <p>
          We do not sell or share your personal information with third parties, except under the following circumstances:
        </p>
        <ul>
          <li><strong>Service Providers:</strong> We may share information with trusted third-party vendors for the purpose of delivering services (e.g., cloud hosting, data storage).</li>
          <li><strong>Legal Requirements:</strong> We may disclose information if required by law or to protect our rights.</li>
        </ul>
      </section>

      <section>
        <h2>4. Data Security</h2>
        <p>
          We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, loss, or misuse. However, no system is entirely secure, and we cannot guarantee complete security.
        </p>
      </section>

      <section>
        <h2>5. Cookies</h2>
        <p>
          We use cookies to enhance user experience on our website. Cookies help us analyze web traffic, personalize content, and remember your preferences. You can choose to disable cookies through your browser settings.
        </p>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <ul>
          <li><strong>Access:</strong> You can request access to your personal information.</li>
          <li><strong>Correction:</strong> You can ask us to correct any inaccuracies in your information.</li>
          <li><strong>Deletion:</strong> You can request the deletion of your personal information, subject to legal or contractual limitations.</li>
          <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional emails by following the unsubscribe instructions.</li>
        </ul>
      </section>

      <section>
        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify users of any significant changes through our website or by direct communication.
        </p>
      </section>

      <section>
        <h2>8. Contact Us</h2>
        <p>If you have any questions or concerns about our Terms and Conditions or Privacy Policy, please contact us at:</p>
        <ul>
          <li>Email: [Insert Email Address]</li>
          <li>Phone: [Insert Phone Number]</li>
          <li>Address: [Insert Company Address]</li>
        </ul>
      </section>
    </div>
    <Footer/>
    </>
  );
};

export default PrivacyPolicy;
