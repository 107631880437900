import React from 'react'
import "./erpframwork.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import ErpImg from "../../Component/Assest/pages/ERP_img/erp-bg.webp"
import { MdKeyboardArrowRight } from "react-icons/md";

import RecentImg1 from "../../Component/Assest/pages/ERP_img/Pricing-Schemes.jpg"
import RecentImg2 from "../../Component/Assest/pages/ERP_img/Quickbooks-vs-Blue-Link-ERP.jpg"
import RecentImg3 from "../../Component/Assest/pages/ERP_img/baby-and-child-items-wholesale-trends.jpg"
import RecentImg4 from "../../Component/Assest/pages/ERP_img/Job-Costing.jpg"
import RecentImg5 from "../../Component/Assest/pages/ERP_img/Pharma-Distributor.jpg"

import Facebook from "../../Component/Assest/pages/ERP_img/facebook.webp"
import Twitter from "../../Component/Assest/pages/ERP_img/twitter.webp"
import Instagram from "../../Component/Assest/pages/ERP_img/Instagram.webp"
import Linkdin from "../../Component/Assest/pages/ERP_img/linkedin.webp"
import BestERP from "../../Component/Assest/pages/ERP_img/best-erp-blog.webp"

import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const Erpframwork = () => {

    const ErpContent = [
        {
            Paragraph1: "In theory, developing a custom ERP solution in-house to manage your business may sound like the perfect solution. However, in practice it rarely is.  Even though the system would be designed with your specific business processes in mind, the benefits gained from a completely customized solution are often outweighed by the resources necessary to maintain such a “perfect fit” system.  Instead, consider working with an industry-specific software company that can meet 80% of your needs out-of-the-box with the option to customize the remaining 20% of your requirements. If you know that you will require some custom work to meet your specific business requirements, you want to find an ERP software vendor who will also become a trusted technology partner. This ensures you get the best of all worlds - a solution that is designed for your specific business type, maintained by a team that provides expertise in the areas of software development and management and provides guidance and insight into industry process improvements. Keep in mind that it's often the smaller vendors who look to build these types of relationships, as it benefits them in the long run as well – working closely with customers means they get first-hand insight into industry-specific requirements, which enables them to continuously enhance the software to better meet the needs of their target market.  Below we take a closer look at the dangers associated with developing custom ERP in-house.",
            Heading1: "Large Resource Investment",
            Paragraph2: "First and foremost, whether you’re a wholesale distribution company, eCommerce business, manufacturing company or operating some other type of business, your core competency is not in creating ERP solutions, nor should it be.  One of the problems with developing a system in-house is that it eats up a lot of resources and requires a large investment into the technology and skills needed to keep the ERP application up and running.  Even if you assign this task to an IT department, it still takes time and other resources away from the activities that contribute to the business's core competency.",
            Heading2: "Lack of Outside Support",
            Paragraph3: "Secondly, homegrown software of any kind does not come with outside support.  ERP vendors have teams of people with a vast range of expertise in areas such as development, data migration, consulting, reporting, process management, training and more.  If something goes wrong with a system developed in-house, it is up to the small team of developers and designers to fix it.  This can often be a painful and time-consuming process and takes resources away from managing other IT processes, and if employees leave, you're stuck trying to find and teach someone about your custom system.",
            Heading3: "Painful Upgrades",
            Paragraph4: "The next issue arises from the fact that completely customized in-house ERP systems are way more difficult to maintain and upgrade. Home-grown solutions often require continuous pieces of customization to manage changing processes, especially for a growing business.  All of these small additions can become unwieldy and leave users stuck when the person who developed them leaves.  The development and design of new features are often limited by the imagination of an individual user and aligned with their specific way of thinking.  Working with an outside software vendor gives you access to the knowledge of a team who has worked with other similar companies, and thus may be able to recommend better ways for managing certain processes. These systems are designed with the best processes in mind to ensure functionality developed does not disrupt other areas of the system or business.  When developing a custom ERP system in-house, you must spend time planning processes, developing the application, testing how it works and then training users on the new features.  When working with an outside vendor, upgrading functionality is often simply a matter of turning on specific features that are already built-in and ready to go.  An example is turning on the ability to do warehouse transfers when your business expands to multiple locations.",
            Heading4: "Outdated Technology",
            Paragraph5: "The next issue pertains to delays in hardware and software technology upgrades as a result of standard capital allocation approval processes.  When developing a system in-house, the technology and infrastructure may not get upgraded as frequently if not properly budgeted for regularly. Other projects may get priority which can increase the risk of system crashes and bugs in the long term.",
            Heading5: "Difficulty Training Employees",
            Paragraph6: "Lastly, home-grown systems are inherently one of a kind, which can be problematic when hiring new employees and dealing with staff turnover.  A completely customized solution means that there are only a few people who know how the system was developed and how to use it.  If those people should decide to leave the company will there be someone to replace them? It can be a challenge to bring new people up to speed on a home-grown system and the amount of time spent doing so is often much higher than when working with an outside vendor. Of course, these concerns are not totally avoidable when using an outside software vendor, which is why it is important to ask the right questions and look for a solution that is intuitive and easy to use.  Outside vendors will automatically have more resources available for training and are often built on existing common technologies such as Microsoft which makes the transition and recovery of data easier.",
            Paragraph7: "While working with an outside ERP vendor to develop a custom solution for your business is preferable to building something in-house, you still run into a lot of the same issues:",
            li1: "Expensive and time-consuming upgrades",
            li2: "Difficulty training employees",
            li3: "Limitations when wanting to add new features",
            Paragraph8: "Fully customized solutions are becoming a thing of the past, as the market for ERP is more saturated than ever before and provides businesses with the opportunity to find a solution that is designed with their type of business in mind.  Most companies will be able to benefit from an out-of-the-box system with simple customization."
        }
    ]

    const Recent = [
        {
            image:RecentImg1,
            title:"How to Avoid Common Deceptive Pricing Practices When Purchasing Business Services",
        },
        {
            image:RecentImg2,
            title:"Blue Link ERP vs. QuickBooks: Which System Fits Your Business?",
        },
        {
            image:RecentImg3,
            title:"Navigating Trends in Baby & Child Items with Technology",
        },
        {
            image:RecentImg4,
            title:"Optimize Cash Flow & Profitability with Job Costing",
        },
        {
            image:RecentImg5,
            title:"DSCSA Guide: WEE’s and Exception Handling",
        },
    ]

    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section className='erp_framwork_section'>
                <div className='erp_framwork_flex'>
                    <div className='content' data-aos="fade-right">
                        <h1>The Dangers of Developing Custom ERP In-House</h1>
                        <p>In theory, developing a custom ERP solution in-house to manage your business may sound like the perfect solution. However, in practice it rarely is.  Even though the system would be designed with your specific business processes in mind, the benefits gained from a completely customized solution are often outweighed by the</p>
                        <span>Auther - <a href='javascript:void(0)'>Sachin Thakur</a></span>
                    </div>
                    <div className='image' data-aos="fade-left">
                        <img src={ErpImg} alt='' />
                    </div>
                </div>

                <div className='erp_content_section'>
                    <div className='erp_content_flex'>
                        <div className='leftbar' data-aos="fade-right">
                            {
                                ErpContent.map((content, index) => (
                                    <div className='erp_content' key={index}>
                                        <p>{content.Paragraph1}</p>
                                        <h3>{content.Heading1}</h3>
                                        <p>{content.Paragraph2}</p>
                                        <h3>{content.Heading2}</h3>
                                        <p>{content.Paragraph3}</p>
                                        <h3>{content.Heading3}</h3>
                                        <p>{content.Paragraph4}</p>
                                        <h3>{content.Heading4}</h3>
                                        <p>{content.Paragraph5}</p>
                                        <h3>{content.Heading5}</h3>
                                        <p>{content.Paragraph6}</p>
                                        <p>{content.Paragraph7}</p>
                                        <ul>
                                            <li>{content.li1}</li>
                                            <li>{content.li2}</li>
                                            <li>{content.li3}</li>
                                        </ul>
                                        <p>{content.Paragraph8}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='rightbar' data-aos="fade-left">
                            <h4>Subscribe to Blog via Email</h4>
                            <p>Enter your email address to subscribe to this blog and receive notifications of new posts by email.</p>
                            <form>
                                <input type='email' placeholder='Email Address' />
                            </form>
                            <button>Subscribe</button>

                            <div className='ul_content'>
                                <ul>
                                    <li><span><MdKeyboardArrowRight /></span> Accounting</li>
                                    <li><span><MdKeyboardArrowRight /></span> Barcode Scanning</li>
                                    <li><span><MdKeyboardArrowRight /></span> Blue Link ERP Software</li>
                                    <li><span><MdKeyboardArrowRight /></span> Business Best Practices</li>
                                    <li><span><MdKeyboardArrowRight /></span> Business Technology</li>
                                    <li><span><MdKeyboardArrowRight /></span> Cloud Computing</li>
                                    <li><span><MdKeyboardArrowRight /></span> Customer Relationship Management</li>
                                    <li><span><MdKeyboardArrowRight /></span> eCommerce</li>
                                    <li><span><MdKeyboardArrowRight /></span> ERP Software</li>
                                    <li><span><MdKeyboardArrowRight /></span> Food Wholesale Distribution</li>
                                    <li><span><MdKeyboardArrowRight /></span> Inventory Management</li>
                                    <li><span><MdKeyboardArrowRight /></span> Microsoft</li>
                                    <li><span><MdKeyboardArrowRight /></span> On-Premises</li>
                                    <li><span><MdKeyboardArrowRight /></span> Pharmaceutical Wholesale Distribution</li>
                                    <li><span><MdKeyboardArrowRight /></span> Replace QuickBooks</li>
                                    <li><span><MdKeyboardArrowRight /></span> Reporting and Analytics</li>
                                    <li><span><MdKeyboardArrowRight /></span> Seed-to-Sale ERP</li>
                                    <li><span><MdKeyboardArrowRight /></span> Software Features</li>
                                    <li><span><MdKeyboardArrowRight /></span> Software Implementation</li>
                                    <li><span><MdKeyboardArrowRight /></span> Software Search</li>
                                    <li><span><MdKeyboardArrowRight /></span> Uncategorized</li>
                                    <li><span><MdKeyboardArrowRight /></span> Warehouse Management</li>
                                    <li><span><MdKeyboardArrowRight /></span> Wholesale Distribution Business</li>
                                </ul>
                            </div>

                            <div className='erp_recent'>
                                <h2>Recent Posts</h2>
                                    {
                                        Recent.map((recent, index) =>(
                                            <div className='recent_card' key={index}>
                                                    <img src={recent.image}/>
                                                    <p>{recent.title}</p>
                                            </div>
                                        ))
                                    }
                            </div>

                            <div className='erp_social_media_icon'>
                                <h2>Share This Story, Choose Your Platform!</h2>
                                <div className='social_media_icon'>
                                        <img src={Facebook} alt=''/>
                                        <img src={Twitter} alt=''/>
                                        <img src={Instagram} alt=''/>
                                        <img src={Linkdin} alt=''/>
                                </div>
                                <img src={BestERP} alt='BestERP' className='besterp'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <OurClient/>
            <Footer />
        </>
    )
}

export default Erpframwork
