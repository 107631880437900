import React from 'react'
import "./blog-sec.css"
import Navbar1 from '../../Navbar/Navbar1'
import Navbar2 from '../../Navbar/Navbar2'
import OurClient from '../../OurClient/OurClient'
import Footer from '../../Footer/Footer'
import { IoMdHome } from "react-icons/io";
import { PiGlobe } from "react-icons/pi";
import Blog1 from "../../Assest/pages/blog/blog_sec.jpg"

const Blog_sec_1 = () => {

  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=9315652636', '_blank');
  };

  const BlogSection = [
    {
      image: Blog1,
      heading: "What Is The Objective Of CodeIgniter?",
      title: "Every programming language has some benefits over its predecessors and CodeIgniter is no exception to this rule. It is the most powerful PHP framework based",
      para: "Stay informed with the latest trends, tutorials, and insights in web development, technology, and design. Master state management in functional components with clear examples. Unlock the potential of responsive web design to make your websites look great on any device using fluid grids, flexible images, and media queries. Explore server-side applications with an easy-to-follow guide covering setup and core concepts. The ES2024 update brings exciting features like enhanced asynchronous handling and updated syntax—check out all the details and stay ahead of the curve.",
      para: "Stay up to date with the latest news, tutorials, and insights in web development, technology, and design. Understand how to manage state in functional components with easy-to-follow examples. Learn to create responsive web designs that ensure your websites are optimized for any device, using fluid grids, flexible images, and media queries. This guide also covers the process of building server-side applications, with clear explanations of setup and essential concepts for beginners. The new ES2024 release introduces exciting enhancements like improved asynchronous handling and updated syntax. Dive into the details of these features now.",
      para: "Stay updated with the latest news, tutorials, and insights on web development, technology, and design. Learn how to manage state in your functional components through simple examples. Understand the basics of responsive web design and get a clear overview of the setup and core concepts for beginners. The latest ES2024 release brings exciting features like improved asynchronous handling and updated syntax. Dive into the details to explore the new enhancements.",
      home: "Home",
      anchor1: "/",
      anchor2: "https://acstechconsulting.com/",
      h1: "1. Automated Communication",
      h2: "2. Predictive Analysis for Admission",
      h3: "3. Enhanced Personalization",
    }
  ]

  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className='blog_sectionPage'>
        <div className='blog_bg'></div>
        <div className='blog_container'>
          <div className='blog_section_flexes'>
            {
              BlogSection.map((item, index) => (
                <div className='blog_section_boxes' key={index}>
                  <div className='img' data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <img src={item.image} />
                  </div>
                  <div className='content' data-aos="fade-left"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine">
                    <h2>{item.heading}</h2>
                    <p>{item.title}</p>
                    <div className='links'>
                      <a href={item.anchor1}><IoMdHome /> {item.home}  </a>

                    </div>
                    <div className="footer-acssocial">
                      <span onClick={handleWhatsAppClick} className="social-icon">
                        <i className="fab fa-whatsapp"></i>
                      </span>
                      <a href="https://www.facebook.com/profile.php?id=61563162418689&mibextid=ZbWKwL" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="https://www.instagram.com/asvconsultingservices?igsh=MTQ0NjVoNGtpbGIwMg==" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/asv-consulting-services/" className="social-icon" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>

          {
            BlogSection.map((text, index) => (
              <div className='text' key={index} >
                <div data-aos="fade-up"
                  data-aos-duration="2000">
                  <h3>{text.h1}</h3>
                  <p>{text.para}</p>
                </div>
                <div data-aos="fade-up"
                  data-aos-duration="2000">
                  <h3>{text.h2}</h3>
                  <p>{text.para}</p>
                </div>
                <div data-aos="fade-up"
                  data-aos-duration="2000">
                  <h3>{text.h3}</h3>
                  <p>{text.para}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <OurClient />
      <Footer />
    </>
  )
}

export default Blog_sec_1
