import React, { useEffect, useState } from "react";
import Logo from "../Component/Assest/1tt.jpeg";
import Navbar1 from "./Navbar/Navbar1";
import Navbar2 from "./Navbar/Navbar2";
import Footer from "./Footer/Footer";
import CarouselComponent from "./Carosel/Carousal";
import AppDevelopmentSection from "./AppDevelopmentSection/AppDevelopmentSection";
import MasterClassSection from "./MasterClassSection/MasterClassSection";

import StatisticsBanner from "./Statics/StaticsBanner";
import Solutions from "./Solutions/Solutions";

import FaqSection from "./FAQ/Faq";
import ProcessSection from "./ProcessSection/ProcessSection";
import OurClient from "./OurClient/OurClient";

import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Home() {
  // useEffect(() => {
  //   setTimeout(() => {
  //     document.getElementById("pupop_btn").click();
  //   }, 3000);
  // }, []);

  const [formData, setFormData] = useState({ name: "", email: "", password: "" });
  // const [changePassword, setChangePassword] = useState(true);
  // const changeIcon = changePassword === true ? false : true;
  const [changePassword, setChangePassword] = useState(true);
  const [changeIcon, setChangeIcon] = useState(true);
  const navigate = useNavigate();

    // Update form field state
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    // Handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      // Here, you can add form validation or API call
      // If successful, redirect to the home page
      navigate("/"); // Redirects to the default home page
    };

  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <CarouselComponent />
      <div style={{ margin: "0px" }}>
        <AppDevelopmentSection />

        <Solutions />
      </div>
      <MasterClassSection />

      <ProcessSection />
      <StatisticsBanner />

      <FaqSection />
      <OurClient />
      {/* <Chatbots/> */}
      <Footer />
      <button
        type="button"
        id="pupop_btn"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        className="modal fade "
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <img className="popup_img" src={Logo} alt="Logo" />
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* <form className="popup_form" onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control input"
          id="name"
          name="name"
          placeholder="username"
          value={formData.name}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control input"
          id="email"
          name="email"
          placeholder="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="password" className="form-label">
          Password
        </label>
        <input
          type={changePassword ? "password" : "text"}
          className="form-control input"
          id="password"
          name="password"
          placeholder="password"
          value={formData.password}
          onChange={handleChange}
        />
        <span
          className="icon"
          onClick={() => {
            setChangePassword(!changePassword);
            setChangeIcon(!changeIcon);
          }}
        >
          {changeIcon ? <FaRegEye className="icons" /> : <FaEyeSlash className="icons" />}
        </span>
      </div>
      <div className="modal-footer">
        <button type="submit" className="submit">
          Submit
        </button>
      </div>
    </form> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
