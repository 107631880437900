import React, { useEffect, useState } from 'react'
import styles from "./jobspage.module.css"
import axios from "axios";
import moment from 'moment';
import eduuxperlogo from "../../../Assest/1tt.jpeg"
import Navbar1 from '../../../Navbar/Navbar1'
import Navbar2 from '../../../Navbar/Navbar2'
import Footer from '../../../Footer/Footer'
import PhpDeveloper from "../../../Assest/php-developer.jpg"
import { Link, useParams } from 'react-router-dom'
import { AiFillClockCircle } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaMapMarked } from "react-icons/fa";
import { MdWifiProtectedSetup } from "react-icons/md";

const Jobspage = () => {

    const { id } = useParams();
    const [job, setJob] = useState([]);
    const [error, setError] = useState("");
    const [showPopup, setShowPopup] = useState(true);
    const [isApplying, setIsApplying] = useState(false);

    useEffect(() => {
        axios
            .get(`https://api.eduuxpert.com/api/job-posts/${id}`)
            .then((response) => {
                setJob(response.data);
                // console.log(response.data);

            })
            .catch((err) => {
                console.log("Error", err);
                setError("Failed to load job details");
            });
    }, [id]);



    const handleLearnMoreClick = () => {
        document.getElementById('pupop_btn').click();
        setShowPopup(true);
    };

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        about_us: "",
        linkedin_id: "",
        nokri_id: "",
        github_id: "",
        resume: null,
        job_id: id,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, resume: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }

        try {
            const response = await axios.post(
                "https://api.eduuxpert.com/api/job-apply",
                formDataToSend,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );
            alert("Application submitted successfully!");
            setIsApplying(false); // Close the form
        } catch (err) {
            alert("Failed to submit application. Please try again.");
        }
    };
useEffect(() => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
}, []);
    return (
        <>
            <Navbar1 />
            <Navbar2 />
            <section>
                <div className={styles.job_container}>
                    <div className={styles.job_links}>
                        <Link to="/career">Careers&nbsp; / </Link>
                        <Link to="/career-jobs">Jobs&nbsp; /</Link>
                        <Link>Engineering</Link>
                    </div>
                    <div className={styles.content_flex}>
                        <div className={styles.left}>
                            <div className={styles.content}>
                                <h2>{job.title}</h2>
                                <p>{job.sub_title}</p>
                            </div>
                            <div className={styles.sub_content}>
                                <div className={styles.sub_text}>
                                    <div className={styles.flex_icon}>
                                        <AiFillClockCircle className={styles.svg_icon} />
                                        <h5>Experience</h5>
                                    </div>
                                    <p>{job.experience}</p>
                                </div>
                                <div className={styles.sub_text}>
                                    <div className={styles.flex_icon}>
                                        <FaUser className={styles.svg_icon} />
                                        <h5>Opening</h5>
                                    </div>
                                    <p>{job.opening}</p>
                                </div>
                                <div className={styles.sub_text}>
                                    <div className={styles.flex_icon}>
                                        <AiFillClockCircle className={styles.svg_icon} />
                                        <h5>CTC</h5>
                                    </div>
                                    <p>{job.salary_range}</p>
                                </div>
                            </div>
                            <div className={styles.interview_process}>
                                <div className={styles.flex_icon}>
                                    <MdWifiProtectedSetup className={styles.svg_icon} />
                                    <h5>Interview Process</h5>
                                </div>
                                <div className={styles.interview}>
                                    {job.interview_process}
                                </div>
                            </div>
                            <div className={styles.sub_content}>
                                <div className={styles.sub_text}>
                                    <div className={styles.flex_icon}>
                                        <FaLocationDot className={styles.svg_icon} />
                                        <h5>Job Location</h5>
                                    </div>
                                    <p>{job.location}</p>
                                </div>
                            </div>
                            <div className={styles.sub_content}>
                                <div className={styles.sub_text}>
                                    <div className={styles.flex_icon}>
                                        <FaMapMarked className={styles.svg_icon} />
                                        <h5>Education</h5>
                                    </div>
                                    <p>{job.education}</p>
                                </div>
                            </div>
                            <div className={styles.applybutton}>
                                <button onClick={handleLearnMoreClick} >Apply Now</button>
                                {/* <Link><button>Share Job</button></Link> */}
                            </div>
                        </div>

                        <div className={styles.right}>
                            <img src={PhpDeveloper} alt="" />
                        </div>
                    </div>

                </div>

                <button
                    type="button"
                    id="pupop_btn"
                    className="btn btn-primary d-none"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                >
                    Launch demo modal
                </button>

                <div className="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">

                        {
                            showPopup && (
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <img className={styles.popup_img} src={eduuxperlogo} alt="Logo" />
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>

                                    <form className={styles.popup_form} onSubmit={handleSubmit}>
                                        <div className="mb-2">
                                            <label htmlFor="name" className="form-label">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control input"
                                                name="name"
                                                placeholder="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="email" className="form-label">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control input"
                                                name="email"
                                                placeholder="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="phone" className="form-label">
                                                Phone No
                                            </label>
                                            <input
                                                type="tel"
                                                className="form-control input"
                                                name="phone"
                                                placeholder="Phone No"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="password" className="form-label">
                                                Message
                                            </label>
                                            <textarea type="text" className="form-control input" name="about_us"
                                                value={formData.about_us}
                                                onChange={handleInputChange}
                                                required></textarea>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="phone" className="form-label">
                                                Linkdin:
                                            </label>
                                            <input
                                                className="form-control input"
                                                type="url"
                                                name="linkedin_id"
                                                value={formData.linkedin_id}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="phone" className="form-label">
                                                Nokri ID:
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control input"
                                                name="nokri_id"
                                                value={formData.nokri_id}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="phone" className="form-label">
                                                GitHub ID:
                                            </label>
                                            <input
                                                type="tel"
                                                className="form-control input"
                                                name="github_id"
                                                value={formData.github_id}
                                                onChange={handleInputChange}
                                            />
                                        </div>


                                        
                                        <div className="mb-2">
                                            <label htmlFor="phone" className="form-label">
                                                Resume:
                                            </label>
                                            <input
                                                className="form-control input"
                                                type="file"
                                                name="resume"
                                                onChange={handleFileChange}
                                                required
                                            />
                                        </div>
                                        <div className="modal-footer">
                                            <button type="submit" className={styles.submit} >
                                                Submit Application
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )
                        }
                    </div>
                </div >

                <div className={styles.content_main}>
                    <div className={styles.job_container}>
                        <h1>Job Description:</h1>
                        <div dangerouslySetInnerHTML={{ __html: job.description }} />
                        <div dangerouslySetInnerHTML={{ __html: job.requirements }} />
                        <div dangerouslySetInnerHTML={{ __html: job.responsibilities }} />
                        <p><strong>Employment Type : </strong> {job.employment_type}</p>
                        <p>
                            <strong>Posted Date : </strong>
                            {moment(job.posted_date).format('MMMM Do, YYYY')}
                        </p>
                        <p>
                            <strong>Application Deadline : </strong>
                            {moment(job.application_deadline).format('MMMM Do, YYYY')}
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
// const stylesModel = {
//     modal: {
//       position: "absolute",
//       top: "50",
//       left: "50px",
//     //   transform: "translate(-50%, -50%)",
//       backgroundColor: "white",
//       padding: "20px",
//       boxShadow: "0px 2px 5px rgba(0,0,0,0.3)",
//       borderRadius: "5px",
//     },
//     form: {
//       display: "flex",
//       flexDirection: "column",
//       gap: "10px",
//     },
//   };
export default Jobspage;
