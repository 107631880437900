import React, { useEffect } from 'react';
import './Solutions.css'; // Assuming you named the CSS file

// const services = [
//   {
//     icon: '📱',
//     delay:'400',
//     title: 'Digital Transformation',
//     description: 'Digital transformation is a strategic initiative that incorporates digital technology across all areas of an organization.',
//   },
//   {
//     icon: '🌐',
//     delay:'500',
//     title: 'Web Development',
//     description: 'A website with seamless flow, visual delights, and enhanced and immersive user experience.',
//   },
//   {
//     icon: '🛒',
//     delay:'600',
//     title: 'Data Management',
//     description: 'Data management is the process of collecting, organizing, and using data in a secure, efficient, and cost-effective manner',
//   },
//   {
//     icon: '🔗',
//     delay:'400',
//     title: 'Web Portal',
//     description: 'Bridge the gap with your customers between your business and customers, vendors, and partners.',
//   },
//   {
//     icon: '📊',
//     delay:'500',
//     title: 'Digital Marketing',
//     description: 'Comprehensive and all-inclusive digital marketing services that transform your ideas into a reality.',
//   },
//   {
//     icon: '📝',
//     delay:'600',
//     title: 'Dedicated Resources',
//     description: 'Every project, big or small, is important; hence, ACS dedicates one resource per client.',
//   }
// ];

const Solution = () => {



  return (
    <>
    {/* <section className="app-development-section">
      <div className="services-container" >
        
        {services.map((service, index) => (
          <div className="service-card" key={index} data-aos="fade-up"
          data-aos-offset="300"
          data-aos-delay={service.delay}
          data-aos-easing="ease-in-sine"  >
            <div className="service-icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </section> */}
    </>
  );
};

export default Solution;