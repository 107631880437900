import React from 'react'
import "./flutterdevelopment.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'
import FluterAbout from "../../Component/Assest/pages/fluter_img/flutter_about.png" 
import FluterCross from "../../Component/Assest/pages/fluter_img/Flutter-cross-platform-framework.png"
import FluterUpdate from "../../Component/Assest/pages/fluter_img/Flutter-for-Web-performance_update.png"
import FluterProject from "../../Component/Assest/pages/fluter_img/flutter_project.png"
import fluterHistoryImg from "../../Component/Assest/pages/fluter_img/fluter_history.png"
import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const FlutterDevelopment = () => {

  const fluterHistory = [
    {
      hspan:"Flutter for web:",
      h1:" let’s start with the basics",
      p1:"Let’s start with the most basic information: ",
      span1:"The what:",
      p2:" Flutter is a Google framework that allows developers to build mobile, web, desktop, and embedded applications using one shared codebase.",
      span2:"The when:",
      p3:" The first stable version of Flutter was released at the end of 2018. ",
      span3:"And the why:",
      p4:" Google created Flutter as a response to the melting budgets of mobile applications and the growing popularity of React Native, becoming its main competitor.",
      p5:"Now let’s move on to some more specific technical information.",
      p6:"Flutter draws attention to Dart, an object-oriented language from Google that, according to many domain specialists, is the future of all mobile, web, and desktop development. Compared with JavaScript (used by its main competitor, React Native), Dart is fully object-oriented and strongly typed. What does this mean for your business? The code delivered in Dart is much more resistant to errors and much easier to maintain, even for many years in a changing team. Undoubtedly, the Flutter programming language is one of its greatest advantages.",
      image:fluterHistoryImg
    }
  ]

  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className='fluter_development'>
        <h1>Flutter Development</h1>
      </div>
      <div className='fluter_content_section'>
          <div className='text'>
            <h2>Flutter for web app development: benefits, threats, applications</h2>
            <p className='paragraph'>Rishabh’s extensive range of React development services will help you build high-performance web & mobile solutions.</p>
          </div>
        <div className='fluter_flex'>
        <div className='content' data-aos="fade-right">
          <p>In 2022, Flutter, the software development kit created by Google, officially outperformed its fiercest competitor, React Native, becoming the new leader in cross-platform technology. According to Google reports, more than 100,000 Flutter-based applications have been released to the public. Despite its growing popularity,</p>
          <p>Flutter is still most well-known as a multiplatform (Android & iOS) mobile development tool. Meanwhile, only a few industry experts know that Flutter can also be used in web applications! Find out the possibilities, applications, and risks associated with Flutter for web development and discover what value it can add to your business.</p>

          <div className='fluter_cross_img'>
            <img src={FluterCross} alt=''/>
          </div>
        </div>
          <div className='image' data-aos="fade-left">
            <img src={FluterAbout} alt='Fluter-About'/>
          </div>
        </div>
      </div>

      <div className='flutter_development'>
        <div className='flutter_development_flex'>
          <div className='fluter_content' data-aos="fade-right">
            <h1>Flutter Development</h1>
            <p> <span className='purple'>Flutter Development</span> Is becoming one of the most exciting solutions available on the market not only because of its cost-effectiveness and time efficiency. With every update, Flutter’s performance is getting closer to native solutions. Of course, <span className='black'>many companies choose Flutter for web as an addition</span> to their mobile product, with a strong focus on increasing the number of customer touchpoints. But is it worth considering Flutter for the <span className='purple'>enterprise web application development</span> solely for its individual features?</p>
          </div>
          <div className='fluter_image' data-aos="fade-left">
            <img src={FluterUpdate} alt=''/>
          </div>
        </div>
      </div>

      <div className='flutter_development'>
        <div className='flutter_development_flex'>
        <div className='fluter_image' data-aos="fade-right">
            <img src={FluterProject} alt=''/>
          </div>
          <div className='fluter_content' data-aos="fade-left">
            <h1>Web Development</h1>
            <p> <span className='purple'>Web Development</span> Is becoming one of the most exciting solutions available on the market not only because of its cost-effectiveness and time efficiency. With every update, Flutter’s performance is getting closer to native solutions. Of course, <span className='black'>many companies choose Flutter for web as an addition</span> to their mobile product, with a strong focus on increasing the number of customer touchpoints. But is it worth considering Flutter for the <span className='purple'>enterprise web application development</span> solely for its individual features?</p>
          </div>
          
        </div>
      </div>

      <div className='fluter_history'>
          {
            fluterHistory.map((item,index) =>(
              <div className='fluter_text' key={index} data-aos="fade-left">
                <h3 className='text_h3'><span>{item.hspan}</span> {item.h1}</h3>
                <p className='paragraph'>{item.p1}</p>
                <p className='paragraph'><span className='bold'>{item.span1}</span> {item.p2}</p>
                <p className='paragraph'><span className='bold'>{item.span2}</span> {item.p3}</p>
                <p className='paragraph'><span className='bold'>{item.span3}</span> {item.p4}</p>
                <p className='paragraph'>{item.p5}</p>
                <p className='paragraph'>{item.p6}</p>
                <div className='history_img'>
                  <img src={item.image} alt=''/>
                </div>
              </div>
            ))
          }
        </div>

        <OurClient/>
      <Footer/>
    </>
  )
}

export default FlutterDevelopment
