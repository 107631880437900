import React from 'react'
import "./iphonedevelop.css"
import Navbar1 from '../../Component/Navbar/Navbar1'
import Navbar2 from '../../Component/Navbar/Navbar2'

import iPhone1 from "../../Component/Assest/pages/iphon-icon/iphone-icon-1.png"
import iPhone2 from "../../Component/Assest/pages/iphon-icon/iphon-icon-2.png"
import iPhone3 from "../../Component/Assest/pages/iphon-icon/iphon-icon-3.png"
import iPhone4 from "../../Component/Assest/pages/iphon-icon/iphon-icon-8.png"
import iPhone5 from "../../Component/Assest/pages/iphon-icon/iphon-icon-9.png"
import iPhone6 from "../../Component/Assest/pages/iphon-icon/iphon-icon-4.png"
import iPhone7 from "../../Component/Assest/pages/iphon-icon/iphon-icon-5.png"
import iPhone8 from "../../Component/Assest/pages/iphon-icon/iphon-icon-6.png"
import iPhone9 from "../../Component/Assest/pages/iphon-icon/iphon-icon-10.png"
import iPhone10 from "../../Component/Assest/pages/iphon-icon/iphon-icon-7.png"

import Icon1 from "../../Component/Assest/pages/iphon-icon/icon-1.webp"
import Icon2 from "../../Component/Assest/pages/iphon-icon/icon-2.png"
import Icon3 from "../../Component/Assest/pages/iphon-icon/icon-3.png"
import Icon4 from "../../Component/Assest/pages/iphon-icon/icon-4.webp"
import Icon5 from "../../Component/Assest/pages/iphon-icon/icon-5.png"
import Icon6 from "../../Component/Assest/pages/iphon-icon/icon-6.png"
import Icon7 from "../../Component/Assest/pages/iphon-icon/icon-7.png"
import Icon8 from "../../Component/Assest/pages/iphon-icon/icon-8.png"

import Footer from '../../Component/Footer/Footer'
import OurClient from '../../Component/OurClient/OurClient'

const IphoneDevelop = () => {

  const Iconcontent = [
    {
      image:Icon1,
      title:"Enhanced Security",
      desc:"Acs application development is oriented towards safeguarding user identity and data privacy for its users. They are safeguarded against all phishing and hacking attempts, apart from viruses and malware. Overall, acs is among the top global performers in terms of data security."
    },
    {
      image:Icon2,
      title:"Filtered Audience",
      desc:"Apple has a loyal consumer base that is high paying and in absolute awe of Apple’s services. The performance of acs apps is flawless and they promote excellent user experience. It is the trust and goodwill associated with iOS apps that have created an elaborate and loyal customer base. Moreover, Apple has been a prime choice for tech-savvy audiences, and acs developers create apps for such users."
    },
    {
      image:Icon3,
      title:"Better user experience",
      desc:"acs apps created by our acs developers promote an excellent user experience that adds to the user delight. With a seamless cohesion between the hardware and the software, the performance of iPhone applications is top grade. Apple users further receive end-to-end customer support and maintenance results. This ensures that customer delight lasts through the apps’ lifecycle."
    },
    {
      image:Icon4,
      title:"Flexible User Interface",
      desc:"Apple devices’ regulated environment adds to the user delight. The user interface is easy to use, which makes it more enjoyable for the end-user. Numerous factors make Apple’s user interface flexible. Apple users can configure their apps on all their Apple devices, and use them seamlessly. The layouts and interface elements change based upon screen sizes. Similarly, they change when one multitasks over the iPad or rotates the screen. Apple’s user interfaces are high on adaptability."
    },
    {
      image:Icon5,
      title:"Secured Transactions",
      desc:"Acs are well secured against all in-app purchases and app-based transactions. This is enabled by the high-end security layer enabled by Apple, along with strong data encryption. This nullifies all instances of data theft, data duplication, and data breach for the enterprises."
    },
    {
      image:Icon6,
      title:"Strong Brand Value",
      desc:"For Acs app development, our apple iOS developers adhere to the high-quality standards set by Apple’s app store. Apple has a loyal customer following and has earned customer trust. Apps available at the Apple app store are fewer than the ones available at Google. But each of the apps meets high-quality standards and delivers top performance. They never disappoint the end consumers."
    },
    {
      image:Icon7,
      title:"Less Development Time",
      desc:"Our Acs developers have an advantage in developing iOS apps quicker than Android apps. Android apps need to be tested over at least 20 devices of different resolutions, OS, and screen sizes. Apple devices have more uniformity in comparison. So, testing and troubleshooting times are lower than Android, by around 28%. Cyblance is the best iOS app development company in India."
    },
    {
      image:Icon8,
      title:"Acs App Re-Engineering",
      desc:"Cyblance is the top acs application development company. Our acs application re-engineering services are customizable, reliable, and end to end. We also make support and maintenance services available for your acs apps. Fine-tuning the performance of an existing application is hence simplified, and delivers you the competitive edge, empowering you to stay ahead in your business."
    },
  ]

  return (
    <>
    <Navbar1/>
    <Navbar2/>
    <div className='iphone_develop'>
      <h1>iPhone Development</h1>
    </div>

    <div className='iphone_category'>
        <div className='top_content'>
          <h1>Explore the line-up.</h1>
          <span>Compare all models</span>
        </div>
        <div className='iphone_category_flex'>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone1} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Built for Apple Intelligence</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
          <div className='iphone_category_image'>
              <img src={iPhone1} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Built for Apple Intelligence</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
          <div className='iphone_category_image'>
              <span>—</span>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
          <div className='iphone_category_image'>
              <span>—</span>
            </div>
          </div>

          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone2} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>A18 Pro chip with 6-core GPU</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
          <div className='iphone_category_image'>
              <img src={iPhone3} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>A18 chip with 5-core GPU</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
          <div className='iphone_category_image'>
          <img src={iPhone4} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>A16 Bionic chip with 5-core GPU</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
          <div className='iphone_category_image'>
          <img src={iPhone5} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>A15 Bionic chip with 5-core GPU</p>
            </div>
          </div>

          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone6} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Camera Control</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
          <div className='iphone_category_image'>
              <img src={iPhone6} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Camera Control</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
          <div className='iphone_category_image'>
              <span>—</span>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
          <div className='iphone_category_image'>
              <span>—</span>
            </div>
          </div>

          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone7} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Pro camera system</p>
              <p>Our most advanced 48MP Fusion</p>
              <p>5x Telephoto camera</p>
              <p>48MP Ultra Wide camera</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
          <div className='iphone_category_image'>
              <img src={iPhone8} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Advanced dual-camera system</p>
              <p>48MP Fusion camera</p>
              <p>2x Telephoto</p>
              <p>12MP Ultra Wide camera</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
          <div className='iphone_category_image'>
              <img src={iPhone9} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Dual-camera system</p>
              <p>48MP Main camera</p>
              <p>2x Telephoto</p>
              <p>12MP Ultra Wide camera</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
          <div className='iphone_category_image'>
              <img src={iPhone9} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Dual-camera system</p>
              <p>12MP Main camera</p>
              <p>—</p>
              <p>12MP Ultra Wide camera</p>
            </div>
          </div>

          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="400">
            <div className='iphone_category_image'>
              <img src={iPhone10} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Up to 27 hours video playback</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="500">
            <div className='iphone_category_image'>
              <img src={iPhone10} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Up to 26 hours video playback</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="600">
            <div className='iphone_category_image'>
              <img src={iPhone10} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Up to 26 hours video playback</p>
            </div>
          </div>
          <div className='iphone_category_box' data-aos="fade-up" data-aos-delay="700">
            <div className='iphone_category_image'>
              <img src={iPhone10} alt='iphone_category_icon'/>
            </div>
            <div className='iphone_category_content'>
              <p>Up to 15 hours video playback</p>
            </div>
          </div>
        </div>
      </div>

      <div className='iphone_development'>
        <h1>acs development services</h1>
        <div className='iphone_development_flex'>
          {
            Iconcontent.map((item, index) =>(
              <div className='iphone_development_cards' key={index}>
                <img src={item.image}/>
                <h3>{item.title}</h3>
                <p>{item.desc}</p>
              </div>
            ))
          }
        </div>
      </div>

      <OurClient/>
    <Footer/>
    </>
  )
}

export default IphoneDevelop