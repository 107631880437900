import React from "react";
import "./androiddevelop.css";
import Navbar1 from "../../Component/Navbar/Navbar1";
import Navbar2 from "../../Component/Navbar/Navbar2";
import Icon1 from "../../Component/Assest/pages/icon-1.svg"
import Icon2 from "../../Component/Assest/pages/icon-2.svg"
import Icon3 from "../../Component/Assest/pages/icon-3.svg"
import Icon4 from "../../Component/Assest/pages/icon-4.svg"
import Icon5 from "../../Component/Assest/pages/icon-5.svg"
import Icon6 from "../../Component/Assest/pages/icon-6.svg"
import feature from "../../Component/Assest/pages/features.jpg"
import Localization from "../../Component/Assest/pages/app-localization.png"
import Analytics from "../../Component/Assest/pages/app-analytics.png"
import Footer from "../../Component/Footer/Footer";
import OurClient from "../../Component/OurClient/OurClient";

const AndroidDevelop = () => {
  return (
    <>
      <Navbar1 />
      <Navbar2 />
      <div className="android_development">
        <h2>Android Development</h2>
      </div>

      <div className="choose_section">
            <div className="text">
              <h1>Mobile App Development </h1>
            </div>
            <div className="choose_flex">
              <div className="content_box">
                <div className="box_text" data-aos="fade-right" data-aos-delay="700">
                  <div className="title">
                    <h4>Concept Design</h4>
                    <p>
                      Ronsectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  <img src={Icon1} alt="" />
                </div>
                <div className="box_text" data-aos="fade-right" data-aos-delay="800">
                  <div className="title">
                    <h4>Mobile Application Development</h4>
                    <p>
                      Ronsectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  <img src={Icon2} alt="" />
                </div>
                <div className="box_text" data-aos="fade-right" data-aos-delay="900">
                  <div className="title">
                    <h4>App Analytics</h4>
                    <p>
                      Ronsectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  <img src={Icon3} alt="" />
                </div>
              </div>

              <div className="content_box">
                <div className="image" data-aos="zoom-in">
                  <img src={feature} alt="" />
                </div>
              </div>

              <div className="content_box">
                <div className="box_text box_text2" data-aos="fade-left" data-aos-delay="700">
                  <img src={Icon4} alt="" />
                  <div className="title">
                    <h4>Mobile Technology Consulting</h4>
                    <p>
                      Ronsectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                </div>
                <div className="box_text box_text2" data-aos="fade-left" data-aos-delay="800">
                  <img src={Icon5} alt="" />
                  <div className="title">
                    <h4>App Localization</h4>
                    <p>
                      Ronsectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                </div>
                <div className="box_text box_text2" data-aos="fade-left" data-aos-delay="900">
                  <img src={Icon6} alt="" />
                  <div className="title">
                    <h4>Maintenance & Support</h4>
                    <p>
                      Ronsectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div className="android_develop_content">
          <div className="container">
          <div className="android_boxes">
            <div className="android_image" data-aos="fade-up"
     data-aos-duration="3000" data-aos-delay="600">
                <img src={Localization} alt="Localization-img"/>
                <h1>App Localization</h1>
            </div>
            <div className="android_content" data-aos="fade-left" data-aos-delay="600"  >
              <p className="para">We help our clients reach global audiences by offering a full experience to the users with localization. It boosts
              app ranking in foreign markets and increase downloads.</p>
              <h3>Mobile-First & Mobile-Only Solutions</h3>
              <p>We have an expert team to take care of the translation of your app content, images, layout, etc. from top-to-toe.</p>
              <h3>MultiLingual</h3>
              <p>We can handle app localization even for right-to-left (RTL) languages like Arabic, Hebrew with ease.</p>
              <h3>Easy to Update</h3>
              <p>We will help you in your new app version release. We detect the new and modified strings and update the translation.</p>
            </div>
          </div>
          </div>
        </div>

        <div className="android_develop_content">
          <div className="container">
          <div className="android_boxes2">
            
            <div className="android_content" data-aos="fade-left" data-aos-delay="600" >
              <p className="para">We understand the breadth and depth of Firebase Analytics and know how to get the insights about the app's user experience.
              The basic implementation is simple but its quite challenging in understanding the audience and marketing accordingly.</p>
              <h3>Understand your user</h3>
              <p>You can track the information about your user by adding additional user properties. Firebase can manage your user
              authentication so that you know the behaviour of the same user accessing the app.</p>
              <h3>Create your audience</h3>
              <p>We help you create segments of your audience. You can then communicate with them in a tailored way using push notifications,
              remote config, targeted ads, etc.</p>
              <h3>Measurements matters most</h3>
              <p>We help our clients understand what KPIs matter most for their app, and then our strategy team suggest the measurements
              that needs to be implemented to ensure its success.</p>
            </div>
            <div className="android_image2" data-aos="fade-up"
     data-aos-duration="3000" data-aos-delay="600">
                <img src={Analytics} alt="Localization-img"/>
                <h1>App Analytics </h1>
            </div>
          </div>
          </div>
        </div>

        <OurClient/>

      <Footer/>
    </>
  );
};

export default AndroidDevelop;
